import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_CONFIG } from '../../../../config/API_CONFIG';
import { _getURL } from '../../../../config/API_CONFIG';


@Injectable(
  { providedIn: 'root' }
)
export class PeriodeChirugieService {


  constructor(private http: HttpClient) { }

  ajoutPeriodeChirugie(periodeChirugie) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.post(_getURL(API_CONFIG.periodeChirugie), periodeChirugie, { headers: headers })
  }
  getPeriodeChirugieByDoctor() {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get<any>(_getURL(API_CONFIG.periodeChirugie) + "/get-periodeChirugies-by-doctor", { headers: headers });
  }
  deletePeriodeChirugie(id) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.delete<any>(_getURL(API_CONFIG.periodeChirugie) + "/delete-periodeChirugie/" + id, { headers: headers });
  }
}
