import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { AuthService } from '../../../services/auth/auth.service';
import { Router } from '@angular/router';
import { PatientService } from '../../../services/profils/patient/patient.service';
import { ExamenService } from '../../../services/examen/examen.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_CONFIG } from '../../../../../config/API_CONFIG';
import { _getURL } from '../../../../../config/API_CONFIG';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.css';
import * as moment from 'moment';
import { Chart } from 'chart.js';
@Component({
  selector: 'app-accueil',
  templateUrl: './accueil.component.html',
  styleUrls: ['./accueil.component.css']
})
export class AccueilComponent implements OnInit {
  myBarChart: any = [];
  canvas: any;
  ctx: any;
  rdvs: any[];
  sizeContent: any;
  patientInfo: any
  patients: any[];
  sizeContentListPatients: number;
  apci = [
    { 'id': '8', 'name': 'Affections coronariennes et leurs complications' },
    { 'id': '3', 'name': 'Affections hypophysaires' },
    { 'id': '4', 'name': 'Affections surrénaliennes' },
    { 'id': '6', 'name': 'Cardiopathies congénitales et valvulopathies' },
    { 'id': '22', 'name': 'Cirrhoses et insuffisance hépatique' },
    { 'id': '1', 'name': 'Diabète insulinodépendant ou non insulinodépendant' },
    { 'id': '2', 'name': 'Dysthyroïdies' },
    { 'id': '13', 'name': 'Epilepsie' },
    { 'id': '23', 'name': 'Glaucome' },
    { 'id': '5', 'name': 'HTA sévère' },
    { 'id': '21', 'name': 'Hépatites chroniques actives' },
    { 'id': '7', 'name': 'Insuffisance cardiaque et troubles du rythme' },
    { 'id': '11', 'name': 'Insuffisance respiratoire chronique' },
    { 'id': '16', 'name': 'Insuffisance rénale chronique' },
    { 'id': '20', 'name': 'Les maladies inflammatoires chroniques de l’intestin' },
    { 'id': '14', 'name': 'Maladie de Parkinson' },
    { 'id': '18', 'name': 'Maladies auto-immunes' },
    { 'id': '24', 'name': 'Mucoviscidose' },
    { 'id': '9', 'name': 'Phlébites' },
    { 'id': '15', 'name': 'Psychoses et névroses' },
    { 'id': '17', 'name': 'Rhumatismes inflammatoires chroniques' },
    { 'id': '12', 'name': 'Sclérose en plaques' },
    { 'id': '10', 'name': 'Tuberculose active' },
    { 'id': '19', 'name': 'Tumeurs et hémopathies malignes' }
  ];
  searchText: any = ''
  filteredPatients: any[] = [];
  sortAscending: boolean = true;
  constructor(
    private authService: AuthService,
    private router: Router,
    private patientService: PatientService,
    private examenService: ExamenService,
    private http: HttpClient
  ) { }
  ngOnInit() {
    if (!localStorage.getItem('medecin')) {
      this.router.navigate(["medecin/patients-liste"]);
      return false;
    }
    window.scrollTo(0, 0);
    this.patientService.getLastTwentyPatient().subscribe((data: any) => {
      if (data && data.success) {
        this.patients = data.data;
        this.sizeContentListPatients = this.patients.length
        this.filteredPatients = [...this.patients];
      }
    },
      err => {
        return false;
      });

    this.examenService.getExamenForToday().subscribe((data: any) => {
      if (data && data.results) {
        this.rdvs = data.results;
        this.sizeContent = this.rdvs.length
        this.patientInfo = this.rdvs[0]
      }
    },
      err => {
        return false;
      });


  }
  ngAfterViewInit() {
    this.canvas = document.getElementById('myChart');
    this.ctx = this.canvas.getContext('2d');
    this.examenService.getstatistiquesExamen().subscribe((dataStat: any) => {
      let myChart = new Chart(this.ctx, {
        type: 'bar',
        data: {
          labels: dataStat.datesSemaine,
          datasets: [{
            label: ' Nombres des rendez-vous',
            //data: [7, 8, 8,5,14],
            data: dataStat.data,
            backgroundColor: [
              '#589590',
              '#23B1A5',
              '#589590',
              '#23B1A5',
              '#589590'
            ],
            borderWidth: 5
          }]
        },
        options: {
          legend: {
            display: false,
          },
          responsive: false,
          scales: {
            xAxes: [{
              stacked: true
            }],
            yAxes: [{
              stacked: true
            }]
          }
        }
      });
    },
      err => {
        return false;
      });



  }

  calculateAge(dateOfBirth) {
    const today = new Date();
    const birthDate = new Date(dateOfBirth);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  }
  getInfoPatient(idRdv) {
    const rdvInfo = this.rdvs.find(rdv => rdv.id === idRdv);
    this.patientInfo = rdvInfo
    if (this.patientInfo && this.patientInfo.id && this.patientInfo.patient && this.patientInfo.patient._id) {
      this.router.navigate(['/medecin/ajout/interrogatoire', this.patientInfo.patient._id, this.patientInfo.id]);
      return false
    }
  }
  getNameById(id: string): string {
    const match = this.apci.find(item => item.id === id);
    return match ? match.name : id;
  }

  sortByName() {
    this.filteredPatients.sort((a, b) => {
      const nameA = a.nom.toLowerCase();
      const nameB = b.nom.toLowerCase();
      return this.sortAscending ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
    });
    this.sortAscending = !this.sortAscending; // Toggle sorting order
  }
  searchPatients(value) {
    this.searchText = value.toLowerCase().trim()
    const search = value.toLowerCase().trim();
    this.filteredPatients = this.patients.filter(pat =>
      pat.nom.toLowerCase().includes(search) ||
      pat.prenom.toLowerCase().includes(search) ||
      pat.email.toLowerCase().includes(search) ||
      pat.tel.toLowerCase().includes(search)
    );
  }
  initiateSearch() {
    this.searchText = ''
    this.filteredPatients = [...this.patients];
  }
}
