<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/fullcalendar/3.6.1/fullcalendar.min.css">
<style media="screen">
  #updateButton {
    background-color: transparent;
    color: #9af0cb;
    border-color: #9af0cb;
  }

  .fc {
    text-transform: capitalize !important;
  }

  .btn-margin {
    margin-bottom: 15px;
  }

  .btn-righ {
    margin-right: 10px;
  }

  .dot {
    height: 15px;
    width: 15px;

    border-radius: 50%;
    display: inline-block;
  }

  #spanColor {
    margin: 0 0 0 5px;
    color: black;
  }
</style>
<section class="mt-5">
  <div id="demo">
    <div class="row">
      <div class="col-9">
        <div class="col-3 float-left">
          <span class="dot" style="background-color: #039be5"></span> <span id="spanColor">Les rendez-vous
            personnels</span>
        </div>
        <div class="col-3 float-left">
          <span class="dot" style="background-color: #23b1a5"></span> <span id="spanColor">Les dates libres</span>
        </div>
        <div class="col-3 float-left">
          <span class="dot" style="background-color: #21726B"></span> <span id="spanColor">Les rendez-vous
            professionnels</span>
        </div>
        <div class="col-3 float-left">
          <span class="dot" style="background-color: #f48a54"></span> <span id="spanColor">Les rendez-vous en
            attente</span>
        </div>
      </div>
    </div>
    <br>
    <!-- <div class="row">
      <div class="col-9" style="margin-left: 15px;width: 71%;">
        <ng-fullcalendar id="calendar" [options]="calendarOptions" style="color:black" (eventClick)="eventClick($event.detail.event)" (dayClick)="dayClick($event.detail)"></ng-fullcalendar>
      </div>
      <div class="col-3">
        <div>
          <h3 class="primary bold-color">Le {{today}}</h3>
          <div class="shadowed" style="padding: 10px;" *ngIf="rdvsToday?.length > 0">
            <div *ngFor="let rd of rdvsToday| paginate: { itemsPerPage: 4, currentPage: p }">
              <h4 class="accent"> {{rd.start| date: 'HH:mm'}}</h4>
              <h4>{{rd.patientId?.nom}} {{rd.patientId?.prenom}}</h4>
              <hr>
            </div>
            <div class="row">
              <div class="col col-xs-6">
                <pagination-controls previousLabel="Précédent " nextLabel="Suivant" (pageChange)="p = $event" #api></pagination-controls>
              </div>
            </div>
          </div>
          <div class="shadowed" style="padding: 10px;" *ngIf="rdvsToday?.length === 0">
            <div>
              <h4 class="accent"> Aucun rendez-vous</h4>

            </div>
          </div>
        </div>
      </div>
    </div>
    <br> -->
    <div class="row">
      <div class="col-12" style="margin-left: 15px;width: 97%;">
        <ng-fullcalendar id="calendar" [options]="calendarOptions" style="color:black" (eventClick)="eventClick($event.detail.event)" (dayClick)="dayClick($event.detail)"></ng-fullcalendar>
      </div>
    </div>
  </div>
  <br><br><br><br>
</section>
<div class="modal fade" id="createEventModal" tabindex="-1" role="dialog" aria-labelledby="myModalExamen" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content modal-content-scan">
      <div class="modal-header d-flex" style="border-bottom: 1px solid #e0e0e0;margin-top: auto;">
        <!-- <h4 class="modal-title">
          Créer un <span (click)="chooseTypeModal('exam')" style="cursor: pointer;text-decoration: underline;">examen </span> / <span (click)="chooseTypeModal('rdv')" style="cursor: pointer;text-decoration: underline;">Rdv</span>
        </h4> -->
        <h4>Créer un Examen</h4>
        <!-- <div class="btn-group modal-title" role="group" aria-label="Basic example">

          <button type="button" class="btn" [ngClass]="{'active':exam===true}" style="color: #23b1a5;" (click)="chooseTypeModal('exam')">Examen</button>
          <button type="button" class="btn" [ngClass]="{'active':rdv===true}" style="color: #23b1a5;" (click)="chooseTypeModal('rdv')">Rendez-vous</button>
        </div> -->

        <a class="exit" aria-label="Close" data-dismiss="modal" (click)="resetForm()"><i class="fa-regular fa-circle-xmark"></i></a>
      </div>
      <div class="modal-body">
        <form [formGroup]="form" class="form-horizontal" role="form">
          <div class="col-12">
            <div class="patient-container">
              <!-- Ancien patient -->
              <div>
                <p for="ancienPatient" class="isLabel font-weight-bold" [ngClass]="{isActiveLabel: typePatient === true}" (click)="togglePatientType(true)">Ancien patient</p>
              </div>
              <!-- Nouveau patient -->
              <div>
                <p for="nouveauPatient" class="isLabel font-weight-bold" [ngClass]="{isActiveLabel: typePatient === false}" (click)="togglePatientType(false)">Nouveau patient</p>
              </div>
            </div>
            <!-- Ancien patient -->
            <div *ngIf="typePatient === true">
              <div *ngIf="patients?.length>0" class="my-2">
                <p for="Type" class="font-weight-bold">Rechercher le patient :<b style="color: red;"> *</b></p>
                <ng-select [items]="patients" bindLabel="fullDetails" placeholder="Rechercher par nom,prénom,date d'anniversaire" [virtualScroll]="true" [(ngModel)]="selectedPatient" formControlName="patient" [clearable]="true" [searchable]="true" (change)="onPatientSelect($event)" [searchFn]="customSearchFn" notFoundText="Aucun élément n'est trouvé" class="form-control input-sm">
                  <!-- Custom display for patients (optional) -->
                  <ng-template ng-option-tmp let-item="item">
                    {{ item.nom }} {{ item.prenom }} {{ item.dateAnniversaire }}
                  </ng-template>
                </ng-select>
                <control-messages [control]="form.controls.patient" class="formControlMessage"></control-messages>
              </div>

              <div *ngIf="patients?.length==0" class="font-weight-bold">
                aucun élément n'est trouvé
              </div>
            </div>
            <!-- Nouveau patient -->
            <div *ngIf="typePatient === false">
              <div formArrayName="patientsArray">
                <div *ngFor="let patient of form.get('patientsArray').controls; let i = index" [formGroupName]="i">

                  <!-- Num Fiche -->
                  <div class="form-group">
                    <label for="num fiche" class="font-weight-bold">N° Fiche <b style="color: red;"> *</b></label>
                    <input formControlName="numFiche" type="text" placeholder="numFiche" class="form-control font-weight-bold" onkeypress="return /[a-zA-Z0-9]/i.test(event.key)" />
                    <control-messages style="color: red;position: relative;top: 20px;left: 15px" [control]="patient.controls.numFiche"></control-messages>
                  </div>


                  <!-- Nom -->
                  <div class="form-group">
                    <label for="nom" class="font-weight-bold">Nom <b style="color: red;"> *</b></label>

                    <input formControlName="nom" type="text" placeholder="Nom" class="form-control font-weight-bold" onkeypress="return /[a-zA-ZàâäéèêëïîôœöùûüÿçÀÂÄÉÈÊËÏÎÔÖÙÛÜŸÇ ]/i.test(event.key)" />
                    <control-messages style="color: red;position: relative;top: 20px;left: 15px" [control]="patient.controls.nom"></control-messages>
                  </div>

                  <!-- Prénom -->
                  <div class="form-group">
                    <label for="prenom" class="font-weight-bold">Prénom <b style="color: red;"> *</b></label>
                    <input formControlName="prenom" type="text" placeholder="Prénom" class="form-control font-weight-bold" onkeypress="return /[a-zA-ZàâäéèêëïîôœöùûüÿçÀÂÄÉÈÊËÏÎÔÖÙÛÜŸÇ ]/i.test(event.key)" />
                    <control-messages style="color: red;position: relative;top: 20px;left: 15px" [control]="patient.controls.prenom"></control-messages>
                  </div>
                  <!-- Date de naissance -->
                  <div class="form-group">
                    <label for="dateAnniversaire" class="font-weight-bold">Date de Naissance <b style="color: red;"> *</b></label>
                    <input formControlName="dateAnniversaire" type="date" class="form-control font-weight-bold" max="{{thisDay |date:'yyyy-MM-dd'}}" />
                    <control-messages style="color: red;position: relative;top: 20px;left: 15px" [control]="patient.controls.dateAnniversaire"></control-messages>
                  </div>

                  <!-- Téléphone -->
                  <div class="form-group">
                    <label for="telephone" class="font-weight-bold">N° Téléphone <b style="color: red;"> *</b></label>
                    <ngx-intl-tel-input class="'form-control font-weight-bold'" [enableAutoCountrySelect]="false" formControlName="tel" [enablePlaceholder]="true" [searchCountryFlag]="true" [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.Tunisia" [maxLength]="8" [phoneValidation]="true"></ngx-intl-tel-input>
                    <control-messages style="color: red;position: relative;top: 20px;left: 15px;" [control]="patient.controls.tel"></control-messages>
                    <div *ngIf="patient.value.tel&&!patient.controls['tel'].invalid == false" class="mt-1" style="color: red;">
                      Ce numéro de téléphone est invalide. <i class="bi bi-x"></i>
                    </div>
                  </div>

                  <!-- Sexe -->
                  <div class="form-group">
                    <label for="sexe" class="font-weight-bold">Sexe <b style="color: red;"> *</b></label>
                    <select formControlName="sexe" class="form-control font-weight-bold">
                      <option value="" disabled selected STYLE="color:#23b1a5 "></option>
                      <option>Homme</option>
                      <option>Femme</option>
                    </select>
                    <control-messages style="color: red;position: relative;top: 20px;left: 15px;" [control]="patient.controls.sexe"></control-messages>
                  </div>


                </div>
              </div>

              <!-- Submit button -->

              <div class="d-flex justify-content-center" *ngIf="addedPatient===false">
                <button type="submit" class="btn btn-primary mb-3 mt-1" [disabled]="!form.get('patientsArray').valid ||submitted==true" (click)="submitNewPatientForm()">Ajouter le patient</button>
              </div>

              <div *ngIf="addedPatient===true">
                <button type="submit" class="btn btn-primary mb-3 mt-1" [ngClass]="{'d-none':'addedPatient===true'}" [disabled]="!form.get('patientsArray').valid ||submitted==true" (click)="submitNewPatientForm()">Ajouter le patient</button>
              </div>

            </div>
            <div *ngIf="showMotif==true">
              <p for="Type" class="font-weight-bold">Motif <b style="color: red;"> *</b></p>
              <ng-select [(ngModel)]="selectedMotif" [items]="motifList" [virtualScroll]="true" [clearable]="true" clearAllText="Effacer le texte" addTagText="Ajouter :" formControlName="motif" [addTag]="true" (change)="addInputMotif($event)"></ng-select>
              <div><span *ngFor="let mot of selectedMotifs">{{mot}} <div class="deleted-btn" title="Supprimer" style="color: red;display: contents;" (click)="removeMotif(mot)">X</div> <br></span></div>
              <control-messages [control]="form.controls.motif" class="formControlMessage"></control-messages>
            </div>
          </div>
          <div class="d-flex justify-content-center mt-4">
            <!-- Ajout de la classe pour centrer -->
            <div class="text-center" *ngIf="addedPatient===true"> <!-- Ajout de la classe pour centrer -->
              <button class=" btn btn-secondary mr-2" data-dismiss="modal" (click)="resetForm()">Annuler</button>
              <button class="btn btn-primary" (click)="createConsultation()">Sauvegarder</button>
            </div>
          </div>
        </form>

      </div>

      <!-- <div id="modalBody" class="modal-body" *ngIf="rdv">
        <form [formGroup]="formRdv" class="form-horizontal" role="form" style="left: 9%;">
          <div class="col-12">
            <label class="choose font-weight-bold" for="Type">Type<span class="required-asterisk">*</span></label>
            <select name="type" (change)="showAndHideTitle()" formControlName="type" class="form-control form-select">
              <option>Personnel</option>
              <option>Professionnel</option>
            </select>
            <control-messages [control]="formRdv.controls.type" class="formControlMessage"></control-messages>
          </div>
          <div class="col-12 form-block" *ngIf="showTitle">
            <label class="choose font-weight-bold" for="titre">Titre</label>
            <input type="text" class="form-control" name="title" formControlName="title" id="title">
            <control-messages [control]="formRdv.controls.title" class="formControlMessage"></control-messages>
          </div>
          <div class="col-12 form-block" *ngIf="!showTitle">
            <label class="choose duration-label font-weight-bold" for="Type">Répétition<span class="required-asterisk">*</span></label>
            <div class="event-durations mb-3">
              <button type="button" class="event-duration" data-duration="15" (click)="updateDuration(15)">15 minutes</button>
              <button type="button" class="event-duration" data-duration="20" (click)="updateDuration(20)">20 minutes</button>
              <button type="button" class="event-duration" data-duration="30" (click)="updateDuration(30)">30 minutes</button>
            </div>

            <select name="timing" formControlName="timing" style="display: none;">
              <option value="15">15min</option>
              <option value="20">20min</option>
              <option value="30">30min</option>
            </select>
            <control-messages [control]="formRdv.controls.type" class="formControlMessage"></control-messages>
          </div>

          <div class="dropdown-cont">
            <div class="col-6">
              <label class="choose font-weight-bold" for="">De<span class="required-asterisk">*</span></label>
              <select name="timeStart" (change)="controlerTimeFermeture()" formControlName="timeStart" class="form-control form-select">
                <option></option>
                <option *ngFor="let hour of hours">{{hour}}</option>
              </select>
              <control-messages [control]="formRdv.controls.timeStart" class="formControlMessage"></control-messages>
            </div>
            <div class="col-6">
              <label class="choose font-weight-bold" for="timeEnd">A<span class="required-asterisk">*</span></label>
              <select name="timeEnd" formControlName="timeEnd" class="form-control form-select">
                <option></option>
                <option *ngFor="let op of optionsTimeFermeture">{{op}}</option>
              </select>
              <control-messages [control]="formRdv.controls.timeEnd" class="formControlMessage"></control-messages>
            </div>
          </div>

          <div class="d-flex justify-content-center mt-4">
            <div class="text-center">
              <button class=" btn btn-secondary mr-2" data-dismiss="modal" (click)="resetForm()">Annuler</button>
              <button class="btn btn-primary" [disabled]="!formRdv.valid" (click)="saveEventRdv()">Sauvegarder</button>
            </div>
          </div>
        </form>
      </div> -->
    </div>
  </div>
</div>
<!-- modal détaille des exams /rdvs -->
<div class="modal fade" id="detailsEventModal" *ngIf="eventClicked" tabindex="-1" role="dialog" aria-labelledby="myModalExamen" aria-hidden="true">
  <!--! détaille examen  -->
  <div class="modal-dialog modal-dialog-centered" role="document" *ngIf="eventClicked.backgroundColor==='#008000'||eventClicked.backgroundColor==='#FF0000'">
    <div class="modal-content modal-content-scan">
      <div class="modal-header d-flex" style="border-bottom: 1px solid #e0e0e0;margin-top: auto;">
        <h4 class="modal-title">
          Détails d'un examen
        </h4>

        <a class="exit" aria-label="Close" data-dismiss="modal"><i class="fa-regular fa-circle-xmark"></i></a>
      </div>
      <div class="modal-body">
        <div>
          <div class="row">
            <!-- First card, shown if eventClicked.type is not present -->
            <div class="col-10" *ngIf="!eventClicked.type">
              <h5 class="card-title">Patient détails</h5>
              <div class="card mb-3" *ngIf="eventClicked?.patient">
                <div class="card-body">
                  <p class="card-text font-weight-bold">
                    Patient :
                    <span class="badge">{{ eventClicked.patient?.nom }} </span> &nbsp; <span class="badge"> {{ eventClicked.patient?.prenom }} </span>
                  </p>
                </div>
              </div>
              <div class="card mb-3" *ngIf="eventClicked?.patient.age">
                <div class="card-body">
                  <p class="card-text font-weight-bold">
                    Age : <span class="badge">{{ eventClicked.patient.age }}</span>
                  </p>
                </div>
              </div>
              <div class="card mb-3" *ngIf="eventClicked?.patient?.profession">
                <div class="card-body">
                  <p class="card-text font-weight-bold">
                    Profession : <span class="badge">{{ eventClicked.patient.profession }}</span>
                  </p>
                </div>
              </div>
              <div class="card mb-3" *ngIf="eventClicked?.motif">
                <div class="card-body">
                  <div class="card-text d-flex flex-wrap  align-items-center font-weight-bold">
                    Motif :&nbsp;
                    <ul class="list-inline mb-0 d-flex flex-wrap  align-items-center">
                      <li class="list-inline-item" *ngFor="let mot of eventClicked.motif">
                        <span class="badge">{{ mot }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="card mb-3" *ngIf="eventClicked?.duration">
                <div class="card-body">
                  <p class="card-text font-weight-bold">
                    Duration : <span class="badge">{{ eventClicked.duration }}</span>
                  </p>
                </div>
              </div>
              <div class="card mb-3" *ngIf="eventClicked?.start">
                <div class="card-body">
                  <p class="card-text font-weight-bold">
                    From :
                    <span class="badge"> {{ eventClicked.start._i | date: 'dd/MM/yyyy , HH:mm' }}</span>
                  </p>
                </div>
              </div>
              <div class="card mb-3" *ngIf="eventClicked?.end">
                <div class="card-body">
                  <p class="card-text font-weight-bold">
                    To :
                    <span class="badge">{{ eventClicked.end._i | date: 'dd/MM/yyyy , HH:mm' }} </span>
                  </p>
                </div>
              </div>
              <div class="card mb-3" *ngIf="eventClicked?.tags.length>0">
                <div class="card-body">
                  <div class="card-text d-flex flex-wrap  align-items-center font-weight-bold">
                    Tags:&nbsp;
                    <ul class="list-inline mb-0 d-flex flex-wrap align-items-center">
                      <li class="list-inline-item" *ngFor="let tag of eventClicked.tags">
                        <span class="badge">{{ tag }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <!-- Second card, shown if eventClicked.type === 'nextAppointment' -->
            <div class="col-10" *ngIf="eventClicked.type === 'nextAppointment'">
              <h5 class="card-title">Prochain rendez-vous</h5>
              <div class="card mb-3" *ngIf="eventClicked?.patient">
                <div class="card-body">
                  <p class="card-text font-weight-bold">
                    Patient :
                    <span class="badge">{{ eventClicked.patient?.nom }} </span> &nbsp; <span class="badge"> {{ eventClicked.patient?.prenom }} </span>
                  </p>
                </div>
              </div>
              <div class="card mb-3" *ngIf="eventClicked?.patient.age">
                <div class="card-body">
                  <p class="card-text font-weight-bold">
                    Age : <span class="badge">{{ eventClicked.patient.age }}</span>
                  </p>
                </div>
              </div>
              <div class="card mb-3" *ngIf="eventClicked?.patient?.profession">
                <div class="card-body">
                  <p class="card-text font-weight-bold">
                    Profession : <span class="badge">{{ eventClicked.patient.profession }}</span>
                  </p>
                </div>
              </div>
              <div class="card mb-3" *ngIf="eventClicked?.motif">
                <div class="card-body">
                  <div class="card-text d-flex flex-wrap  align-items-center font-weight-bold">
                    Motif :&nbsp;
                    <ul class="list-inline mb-0 d-flex align-items-center">
                      <li class="list-inline-item" *ngFor="let mot of eventClicked.motif">
                        <span class="badge">{{ mot }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="card mb-3" *ngIf="eventClicked?.start">
                <div class="card-body">
                  <p class="card-text font-weight-bold">
                    At :
                    <span class="badge"> {{ eventClicked.start._i | date: 'dd/MM/yyyy , HH:mm' }}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="d-flex justify-content-center mt-4">
            <!-- Ajout de la classe pour centrer -->
            <div class="text-center"> <!-- Ajout de la classe pour centrer -->
              <button class="btn btn-secondary mr-2" data-dismiss="modal">Annuler</button>
              <button class="btn btn-primary" (click)="geToUpdateConsultation(eventClicked)">Modifier</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--! détaille rdv et evenement  -->
  <!-- <div class="modal-dialog modal-dialog-centered" role="document" *ngIf="eventClicked.backgroundColor==='#039be5'||eventClicked.backgroundColor==='#23b1a5'">
    <div class="modal-content" style="width:100%">
      <div class="modal-header d-flex" style="border-bottom: 1px solid #e0e0e0;margin-top: auto;">
        <h4 class="modal-title">
          Détails d'un rendez-vous
        </h4>
        <a class="exit" aria-label="Close" data-dismiss="modal"><i class="fa-regular fa-circle-xmark"></i></a>
      </div>

      <form [formGroup]="updateformRdv" style="position: relative;" class="mt-2">
        <div id="modalBody" class="modal-body" style="position: relative;padding: 0px;">
          <div class="col-12">
            <label for="Type" class="font-weight-bold">Type <b style="color: red;"> *</b></label>
            <select name="type" formControlName="type" style="width:80%" value="{{eventClicked.type}}" [(ngModel)]="eventClicked.type" class="form-control">
              <option>Personnel</option>
              <option>Professionnel</option>
            </select>
            <control-messages [control]="updateformRdv.controls.type"></control-messages>
          </div>
          <div class="col-12 mt-2" *ngIf="eventClicked.type == 'Personnel'">
            <label for="" class="font-weight-bold">Titre</label>
            <input type="text" class="form-control" formControlName="title" [(ngModel)]="eventClicked.title" style="width:80%" name="eventClicked.title" id="title" value="{{eventClicked.title}}">
            <control-messages [control]="updateformRdv.controls.title"></control-messages>
          </div>
          <div class="col-12 mt-2">
            <label for="" class="font-weight-bold">De <b style="color: red;"> *</b></label>
            <input class="form-control" formControlName="start" name="eventClicked.start" style="width:80%" [(ngModel)]="eventClicked.start" id="start" type="datetime-local" required="required" />
            <p *ngIf="showErrorDate" style="color:red">Vérifier les dates</p>
            <control-messages [control]="updateformRdv.controls.start"></control-messages>

          </div>
          <div class="col-12 mt-2">
            <label for="" class="font-weight-bold">À <b style="color: red;"> *</b></label>
            <input class="form-control" formControlName="end" name="end" name="eventClicked.end" style="width:80%" [(ngModel)]="eventClicked.end" id="end" type="datetime-local" required="required" />
            <control-messages [control]="updateformRdv.controls.end"></control-messages>
          </div>
          <div class="d-flex justify-content-center mt-4 mb-3">

            <div class="text-center">
              <button class="btn btn-secondary mr-2" (click)="modifierEvent(eventClicked)" [disabled]="!updateformRdv.valid" data-dismiss="modal">Modifier</button>
              <ng-container>
                <button class="btn dropdown-toggle outils btn-danger" data-toggle="dropdown">Supprimer</button>
                <ul class="dropdown-menu profil-menu p-2">
                  <li style="cursor: pointer;"><a (click)="deleteEvent(eventClicked)" data-dismiss="modal" aria-hidden="true">Cet événement</a></li>

                  <li class="mt-1" style="cursor: pointer;"><a (click)="deleteEventSerie(eventClicked)" data-dismiss="modal" aria-hidden="true">Tous les événements de la série</a></li>
                </ul>
              </ng-container>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div> -->
</div>
<!--! modal suppression  -->
<!-- modal de suppression des evenement -->
<!-- <div *ngIf="showDeleteModal" class="modal fade show d-block" tabindex="-1" role="dialog" aria-labelledby="myModalExamen" id="supprimerEventModal">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content" style="width:100%">
      <div class="modal-header d-flex" style="border-bottom: 1px solid #e0e0e0;margin-top: auto;">
        <h4 class="modal-title">
          Suppression de disponibilité
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div id="modalBody" class="modal-body" style="position: relative;padding: 0px;">
        <div class="col-12">
          <label class="font-weight-bold">
            Voulez-vous vraiment supprimer
            {{ deleteMode === 'series' ? 'cette liste de disponibilité' : 'cette disponibilité' }} ?
          </label>
        </div>
        <div class="d-flex justify-content-center mt-4 mb-3">
          <div class="text-center">
            <button class="btn btn-secondary mr-2" (click)="closeModal()">Annuler</button>
            <button class="btn btn-primary" (click)="confirmDelete()">Supprimer</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div id="detailsEventModalDemandeRDV" class="modal fade" *ngIf="eventClicked">
  <div class="modal-dialog">
    <div class="modal-content" style="width:100%;height: 500px;">
      <div class="modal-header">
        <h4 style="color:black;margin: auto;">Demande de la prise de rendez-vous</h4>
      </div>
      <div id="modalBody" class="modal-body" style="position: relative;padding: 0px;height: 290px;">
        <form class="form-horizontal" role="form" *ngIf="!showAndHideDelete">
          <div class="col-12">
            <br>
            <h5 class="card-adress"> Motif de consultation : {{eventClicked.motif}}</h5>
            <h5 class="card-adress"> Nom et prénom du patient : {{eventClicked.nom_patient }}
              {{eventClicked.prenom_patient}}</h5>
            <h5 class="card-adress"> Email : {{eventClicked.email}}</h5>
            <h5 class="card-adress"> Numéro de téléphone personnel : {{eventClicked.tel}}</h5>
            <h5 class="card-adress"> Consultation de médecine générale : {{eventClicked.start | date: 'dd/MM/yyyy à HH:mm'}} </h5>
            <br>
          </div>
          <div class="modal-footer d-flex justify-content-center" style="border:none">
            <div class=" col d-flex justify-content-center buttonsPopup">
              <div class="col-auto nbr-dossier ">
                <table>
                  <tr>
                    <td class="tdButt1 ">
                      <button class="btn-valider" style="width: 115px;font-size: 13px;" data-dismiss="modal" aria-hidden="true" (click)="AcceptedDemande(eventClicked)">Accepter </button>
                    </td>
                    <td class="tdButt2 ">
                      <div class="dropdown" style="float: inherit;margin-right: 5px;">
                        <button type="button" name="button" style="width: 115px;font-size: 13px;" class="btn-annule dropdown-toggle outils btn btn-danger" data-toggle="dropdown"><br>Refuser</button>
                        <ul class="dropdown-menu profil-menu">
                          <li style="cursor: pointer;"><a (click)="refuserDemande(eventClicked)"><i class=""></i>La
                              demande</a></li>
                          <li class="divider"></li>
                          <li style="cursor: pointer;"><a (click)="hideDelete()">La demande et proposer une autre
                              date</a></li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </form>
        <form class="form-horizontal" role="form" [formGroup]="deleteAndProposeForm" *ngIf="showAndHideDelete" style="position: relative;left: 7%;">
          <div class="col-12">
            <label for="">Jour*</label>
            <input class="form-control" formControlName="jour" name="jour" type="date" required="required" style="width:80%;border: none;background-color: #e8f6f5;padding: 1% 3%;width: 80%;" />
            <control-messages [control]="deleteAndProposeForm.controls.jour"></control-messages>
          </div>
          <div class="col-12">
            <label for="">De*</label>
            <input class="form-control" formControlName="start" name="start" type="time" required="required" style="width:80%;border: none;background-color: #e8f6f5;padding: 1% 3%;width: 80%;" />
            <p *ngIf="showErrorDate" style="color:red">Vérifier les dates</p>
            <control-messages [control]="deleteAndProposeForm.controls.start"></control-messages>
          </div>
          <div class="col-12">
            <label for="">À*</label>
            <input class="form-control" formControlName="end" name="end" type="time" required="required" style="width:80%;border: none;background-color: #e8f6f5;padding: 1% 3%;width: 80%;" />
            <control-messages [control]="deleteAndProposeForm.controls.end"></control-messages>
          </div>
          <div class="col-12">
            <button class="btn-valider" style="width: 115px;font-size: 13px;margin-left: 30%;" [disabled]="!deleteAndProposeForm.valid" (click)="proposerOtherDate(eventClicked)"><img src="assets/images/listePatsEspaceMed/valider.png" class="iconBoutton">Valider </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div> -->
