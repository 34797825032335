<div class="container-fluid p-sm-5 p-3 bg-light">
  <!-- <div class="row">
        <div class="col-lg-12">
            <ul class="breadcrumb float-md-right">
                <li class="breadcrumb-item active"><span><i class="fa-solid fa-house"></i> Accueil</span></li>
            </ul>
        </div>
    </div> -->
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <div class="mb-4">
        <h5>Mes Rendez-vous</h5>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12 d-flex mb-2">
      <div [ngClass]="sizeContent > 8 ? 'scroller' : 'scroller2'" class="table-responsive">
        <table class="table" *ngIf="rdvs && rdvs.length > 0">
          <thead>
            <tr class="ligne-paire">
              <th class="text-center color-green" scope="col">Patient</th>
              <th class="text-center color-green" scope="col">Motif</th>
              <th class="text-center color-green" scope="col">Heure</th>
              <th class="text-center color-green" scope="col">Statut</th>
            </tr>
          </thead>
          <tbody *ngFor="let rdv of rdvs; let k = index">
            <tr [class.ligne-paire]="k % 2 !== 0" [class.ligne-impaire]="k % 2 === 0" (click)="getInfoPatient(rdv.id)">
              <td class="text-center" *ngIf="rdv.patient">{{ rdv.patient.nom }} {{ rdv.patient.prenom }}</td>
              <td class="text-center">
                <p *ngFor="let rd of rdv.motif">{{ rd }}</p>
              </td>
              <td class="text-center">{{ rdv.start | date:'HH:mm' }}</td>
              <td class="text-center"><input [id]="'cb' + k" [checked]="rdv.passed" type="checkbox"></td>
            </tr>
          </tbody>
          <!-- <tbody *ngIf="rdvs && rdvs.length === 0 || !rdvs">
                      <tr>
                        <td></td>
                        <td colspan="3">La liste est actuellement vide. Aucune donnée n'est disponible pour le moment.</td>
                      </tr>
                    </tbody> -->
        </table>
        <div *ngIf="rdvs && rdvs.length === 0 || !rdvs" class="card w-100 h-100">
          <div class="card-body d-flex align-items-center justify-content-center">
            <p style="color: #589590; font-weight: bold;font-size: 1.3rem;">La liste est actuellement vide. Aucune donnée n'est disponible pour le moment.</p>
          </div>
        </div>

      </div>
    </div>
  </div>
  <div class="row mt-5">
    <div class="col-lg-6 col-md-12 col-sm-12 d-flex flex-column">
      <div class="d-flex justify-content-between align-items-center mb-4">
        <h5>Patient</h5>
        <button [routerLink]="['/medecin/nouveauPatient']" class="btn btn-primary">Ajouter un patient</button>
      </div>
      <div class="d-flex align-items-baseline" *ngIf="sizeContentListPatients > 0">
        <input type="text" class="form-control w-25 input-sm mb-1" [(ngModel)]="searchText" (input)="searchPatients($event.target.value)" placeholder="Rechercher un patient...">
        <i class="fa-solid fa-arrows-rotate ml-2" id="initialSearch" title="Reset recherche" (click)="initiateSearch()"></i>
      </div>

      <div [ngClass]="sizeContentListPatients > 8 ? 'scroller' : 'scroller2'" class="table-responsive">
        <table class="table" *ngIf="filteredPatients && filteredPatients.length > 0">
          <thead>
            <tr class="ligne-paire">
              <th class="text-center color-green" scope="col" (click)="sortByName()" style="cursor: pointer;">
                Nom <span *ngIf="sortAscending" id="spanColor">&#9650;</span><span *ngIf="!sortAscending" id="spanColor">&#9660;</span>
              </th>
              <th class="text-center color-green" scope="col">Prénom</th>
              <th class="text-center color-green" scope="col">Email</th>
              <th class="text-center color-green" scope="col">Téléphone</th>
            </tr>
          </thead>
          <tbody *ngFor="let pat of filteredPatients; let k = index">
            <tr [class.ligne-paire]="k % 2 !== 0" [class.ligne-impaire]="k % 2 === 0" [routerLink]="['/medecin/consultation-liste', pat._id]" title="Détails du patient">
              <td class="text-center">{{ pat.nom }}</td>
              <td class="text-center">{{ pat.prenom }}</td>
              <td class="text-center">{{ pat.email }}</td>
              <td class="text-center">{{ pat.tel }}</td>
            </tr>
          </tbody>

        </table>
        <div *ngIf="patients && patients.length === 0 || !patients" class="card w-100 h-100">
          <div class="card-body d-flex align-items-center justify-content-center">
            <p style="color: #589590; font-weight: bold;font-size: 1.3rem;">La liste est actuellement vide. Aucune donnée n'est disponible pour le moment.</p>
          </div>
        </div>
        <div *ngIf="filteredPatients && filteredPatients.length === 0 || !filteredPatients" class="card w-100 h-50">
          <div class="card-body d-flex align-items-center justify-content-center">
            <p style="color: #589590; font-weight: bold;font-size: 1.3rem;">Aucun élément n'est trouvé...</p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6 col-md-12 col-sm-12 d-flex flex-column">
      <div class="d-flex justify-content-between align-items-center mb-4">
        <h5>Statistiques de la semaine dernière </h5>
      </div>
      <div class="card">
        <div class="card-body">
          <canvas id="myChart" style="width: 100%; height: 42vh;"></canvas>
        </div>
      </div>
    </div>
  </div>
</div>
