import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms'
import { ConsultationService } from '../../../../services/consultation/consultation.service';
import { Router } from '@angular/router';
import { ValidateService } from '../../../../services/validate/validate.service';
import { AuthService } from '../../../../services/auth/auth.service';
import { ValidationService } from '../../../../services/validate/validateError.service';
import { MedecinService } from '../../../../services/profils/medecin/medecin.service';
import { pays } from '../../../pays/pays';
import { tri } from '../../../pays/pays';
import { villes } from '../../../pays/villes';
import { codepostale } from '../../../pays/villes';
import { professions } from '../../../pays/professions';
import 'sweetalert2/dist/sweetalert2.css';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import { champsObligatoire, contactAdmin } from 'src/app/services/constants/messages';
import { Ng2IzitoastService } from 'ng2-izitoast';

@Component({
  selector: 'app-patient',
  templateUrl: './patient.component.html',
  styleUrls: ['./patient.component.scss']
})
export class PatientComponent implements OnInit {
  today = new Date();
  patients: any[] = [];
  medecins: any[] = [];
  form: any;
  medecin: any;
  age: any;
  interval: any;
  messages: any[] = [];
  secretaire: any;
  public items: Array<any> = [];
  private value: any = ['Athens'];
  erroNumeroDeFiche = false;
  showAutres = false;
  showOrg = false;
  isValid = true;
  pays: any[] = []
  selectedpays = "Tunisie"
  selectedville = ''
  selectedvilleEtablissement = ''
  listePostal = [];
  listePostalEtablissement = [];
  listefiltrecode = ['2001', '2002', '2020', '2022', '2027', '2032', '2035', '2036', '2037', '2041', '2056', '2057', '2058', '2061', '2073', '2080', '2081', '2083', '2088', '2091', '2094', '2095']
  listefiltrecodeEtab = ['2001', '2002', '2020', '2022', '2027', '2032', '2035', '2036', '2037', '2041', '2056', '2057', '2058', '2061', '2073', '2080', '2081', '2083', '2088', '2091', '2094', '2095']
  listeVilles: any[] = [];
  isTunisie = true
  listefiltreville = [];
  selectedpostal = '';
  selectedpostalEtablissement = ''
  professions: any[] = professions
  showProessionAutre = false
  showMyContainer = 1;
  public optionsss: any;
  optionss: any;
  sizeLimitOne = 5000000; //5MB
  filterMedecins: any[] = []
  mymedcins: Array<any> = [];
  specialiteSelected: any;
  showerrorligne = ""
  mydate = new Date().toISOString().split('T')[0]
  showedValue: any;
  selectedMedecinn: any;
  screenWidth: any
  ng2slectwidh: any
  existingMedivIds: any[]

  //
  submitted = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  apcis = [
    'Affections coronariennes et leurs complications', 'Affections hypophysaires', 'Affections surrénaliennes', 'Cardiopathies congénitales et valvulopathies',
    'Cirrhoses et insuffisance hépatique', 'Diabète insulinodépendant ou non insulinodépendant', 'Dysthyroïdies', 'Epilepsie', 'Glaucome', 'HTA sévère', 'Hépatites chroniques actives',
    'Insuffisance cardiaque et troubles du rythme', 'Insuffisance respiratoire chronique', 'Insuffisance rénale chronique', 'Les maladies inflammatoires chroniques de l\'intestin',
    'Maladie de Parkinson', 'Maladies auto-immunes', 'Mucoviscidose', 'Phlébites', 'Psychoses et névroses', 'Rhumatismes inflammatoires chroniques', 'Sclérose en plaques',
    'Tuberculose active', 'Tumeurs et hémopathies malignes'
  ]
  champsObligatoire = champsObligatoire
  medecinId: any;
  constructor(private router: Router, private consultationService: ConsultationService, private medecinService: MedecinService,
    private formBuilder: FormBuilder, private validateService: ValidateService, private responsiveService: ResponsiveService,
    private authService: AuthService, public iziToast: Ng2IzitoastService) {
    this.form = new FormGroup({});
    this.form = this.formBuilder.group({
      nom: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(25), ValidationService.validateChamps]],
      prenom: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(25), ValidationService.validateChamps]],
      profession: [null, [Validators.minLength(3), Validators.maxLength(75), ValidationService.validateProfession]],
      autreProfession: ['', [Validators.minLength(3), Validators.maxLength(75), ValidationService.validateChamps]],
      dateAnniversaire: ['', [Validators.required, ValidationService.dateValidator]],
      numFiche: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(25), ValidationService.validateChampsNumFiche]],
      statusFamilial: [''],
      groupeSanguin: [''],
      sexe: ['', [Validators.required]],
      apci: [[],],
      email: ['', [ValidationService.emailValidator(this.validateService), Validators.maxLength(50)]],
      tel: ['', [Validators.required]],
      orgSante: this.formBuilder.group({
        typeOrg: [''],
        nomEtablissement: ['', [Validators.maxLength(25), ValidationService.validateChamps]],
        email: ['', [ValidationService.emailValidator(this.validateService), Validators.maxLength(50)]],
        tel: ['+216'],
        adresse: this.formBuilder.group({
          ville: ['', [Validators.maxLength(25), ValidationService.validateChamps]],
          codePostal: ['', [ValidationService.numberValidator, Validators.maxLength(4)]],
          adresse: ['', Validators.maxLength(500)],
        }),
      }),
      adresse: this.formBuilder.group({
        adresse: ['', [Validators.minLength(3), Validators.maxLength(500)]],
        ville: ['', [Validators.minLength(3), Validators.maxLength(25), ValidationService.validateChamps]],
        pays: ['Tunisie'],
        codePostal: ['', [ValidationService.numberValidator, Validators.maxLength(4)]],
      })

    });
    this.optionsss = {
      multiple: true,
      maximumSelectionLength: 5
    }
  }
  get f() { return this.form.controls; }
  public selected(value: any): void { }
  public removed(value: any): void { }
  public typed(value: any): void { }
  public refreshValue(value: any): void {
    this.value = value;
  }

  ngOnInit() {
    this.screenWidth = window.innerWidth;
    // Subscribe to screen width changes
    this.responsiveService.getScreenWidth().subscribe((width) => {
      this.screenWidth = width;
      if (this.screenWidth < 768) {
        // Handle small screens
        this.ng2slectwidh = 500
        return true;
      } else if (this.screenWidth >= 768 && this.screenWidth < 1024) {
        // Handle medium screens
        this.ng2slectwidh = 1000
        return true;
      } else {
        // Handle large screens
        this.ng2slectwidh = 1400
        return true;
      }
    });
    this.pays = pays.sort(tri);
    if (localStorage.getItem('medecin')) {
      this.authService.getProfileMedecin().subscribe((profile: any) => {
        this.medecin = profile.medecin;
        if (!this.medecin.roles.gestionPatient) {
          this.router.navigate(['/notFound']);
          return false;
        }
        let identifiantMedivisto = this.medecin.identifiantMedivisto; // Example value
        const regex = /^Mediv-M\d+$/;
        if (!identifiantMedivisto || !regex.test(identifiantMedivisto)) {
          this.router.navigate(['/medecin/patients-liste']);
          this.iziToast.show({
            message: contactAdmin,
            messageColor: '#800f2f',
            titleColor: '#800f2f',
            progressBarColor: '#c9184a',
            position: 'topRight',
            timeout: 5000,
            backgroundColor: '#ff8fa3',
            transitionIn: 'flipInX',
            transitionOut: 'flipOutX',
            overlay: true,
            overlayClose: true,
          });
          return false
        }
        this.medecinId = this.medecin._id
        this.getPatients()
      },
        err => {
          return false;
        });
    }
    if (localStorage.getItem('secretaire')) {
      this.authService.getProfileSecretaire().subscribe((profile: any) => {
        this.secretaire = profile.secretaire;
        if (!this.secretaire.roles.gestionPatient) {
          this.router.navigate(['/notFound']);;
          return false;
        }
        this.medecinId = this.secretaire.medecinId
        this.getPatients()
        this.medecinService.getMedecin(this.secretaire.medecinId).subscribe(medecin => {
          this.medecin = medecin;

          let identifiantMedivisto = this.medecin.identifiantMedivisto;
          const regex = /^Mediv-M\d+$/;
          if (!identifiantMedivisto || !regex.test(identifiantMedivisto)) {
            this.router.navigate(['/medecin/patients-liste']);
            this.iziToast.show({
              message: contactAdmin,
              messageColor: '#800f2f',
              titleColor: '#800f2f',
              progressBarColor: '#c9184a',
              position: 'topRight',
              timeout: 5000,
              backgroundColor: '#ff8fa3',
              transitionIn: 'flipInX',
              transitionOut: 'flipOutX',
              overlay: true,
              overlayClose: true,
            });
            return false
          }
        },
          err => {
            return false;
          });
      },
        err => {
          return false;
        });
    }

  }
  getPatients() {
    this.consultationService.getAllPatientsByMedecin(this.medecinId)
      .subscribe((patients: any[]) => {
        this.patients = patients;
        if (this.patients) {
          this.patients = patients.sort(this.triapatients);

          //--- getting the existing MedivID
          this.existingMedivIds = this.patients
            .map(el => el.identifiantMedivisto)
            .filter(id => id !== null && id !== undefined);
        }
      });

  }
  triapatients(a, b) {
    if (a.created_at > b.created_at) return -1;
    else if (a.created_at == b.created_at) return 0;
    else return 1;
  }
  private scrollToBottom(elem) {
    elem.scrollTop = elem.scrollHeight;
  }
  onSelectville(selectedville) {
    this.listePostal = codepostale.filter(x => x.ville == selectedville);
    this.listePostal.forEach(index => this.listefiltrecode = index.code);
  }
  onSelectvilleEtablissement(selectedvilleEtablissement) {
    this.listePostalEtablissement = codepostale.filter(x => x.ville == selectedvilleEtablissement);
    this.listePostalEtablissement.forEach(index => this.listefiltrecodeEtab = index.code);
  }
  onSelectpays(selectedpays) {
    if (selectedpays === 'Tunisie' || selectedpays === '') {
      this.isTunisie = true;
      this.listeVilles = villes.filter(x => x.pays == selectedpays);
      this.listeVilles.forEach(index =>
        this.listefiltreville = index.ville);
    } else {
      this.selectedville = undefined
      this.selectedpostal = undefined
      this.form.controls['adresse'].controls['ville'].setValue('');
      this.form.controls['adresse'].controls['codePostal'].setValue('');
      this.isTunisie = false;
    }
  }
  onRegisterPatient() {
    let identifiantMedivisto = this.medecin.identifiantMedivisto; // Example value

    // stop here if form is invalid
    if (this.form.invalid) {
      this.markFormGroupTouched(this.form);
      return false;
    }

    if (this.form.value.tel) {
      this.form.value.tel = this.form.value.tel.internationalNumber
    }
    if (!this.form.value.nom || this.form.value.nom.length < 3 || this.form.value.nom.length > 25 || !this.form.value.prenom || this.form.value.prenom.length < 3 || this.form.value.prenom.length > 25) {
      this.showerrorligne = "Merci de vérifier les champs 'nom et prénom' (minimum 3, maximum 25)"
      return false;
    }
    if (!this.form.value.sexe) {
      this.showerrorligne = "Merci de vérifier le champ 'sexe'"
      return false;
    }
    if (!this.form.value.dateAnniversaire || this.form.value.dateAnniversaire > this.mydate) {
      this.showerrorligne = "Merci de vérifier le champ 'date de naissance'"
      return false;
    }

    this.showerrorligne = ""
    this.isValid = false
    setTimeout(() => {
      this.isValid = true
    },
      30000);
    var today = new Date();
    this.erroNumeroDeFiche = false;
    if (this.showProessionAutre) {
      this.form.value.profession = this.form.value.autreProfession
    }
    var birthDate = new Date(this.form.value.dateAnniversaire);
    this.age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      this.age--;
    }
    this.form.value.listMedecinsId = this.medecinId;
    this.form.value.createdByDoctor = true;
    this.form.value.age = this.age;
    this.form.value.expiredAt = null


    let medivId = this.generateMedivId(identifiantMedivisto, this.existingMedivIds)
    this.form.value.identifiantMedivisto = medivId
    this.consultationService.registerPatient(this.form.value)
      .subscribe((patient: any) => {
        if (patient && patient._id) {
          this.iziToast.show({
            message: 'Bien ajouté',
            messageColor: '#386641',
            progressBarColor: '#6a994e',
            position: 'topRight',
            timeout: 5000,
            backgroundColor: '#dde5b6',
            transitionIn: 'flipInX',
            transitionOut: 'flipOutX',
            overlay: false,
          });
          this.router.navigate(['/medecin/consultation-liste', patient._id]);
          this.form.reset();
          return true;
        }
        if ((!patient) || (patient && !patient._id)) {
          setTimeout(() => {
            this.router.navigate(['/medecin/patients-liste']);
          }, 2000);
          this.form.reset();
          return true;
        }
      });
  }

  generateMedivId(identifiantMedivisto: string, existingIds: string[]): string {
    let newPatientNumber = 1; // Default to 1 if no IDs exist

    // Filter and extract valid numbers from IDs matching the same doctor
    const filteredNumbers = existingIds
      .map(id => {
        const match = id.match(new RegExp(`${identifiantMedivisto}-(\\d+)$`)); // Extract the number part
        return match ? parseInt(match[1], 10) : null; // Convert to number
      })
      .filter(num => num !== null && !isNaN(num)) as number[]; // Ensure valid numbers

    if (filteredNumbers.length > 0) {
      newPatientNumber = Math.max(...filteredNumbers) + 1; // Get the highest number and increment
    }

    return `${identifiantMedivisto}-${newPatientNumber}`;
  }

  markFormGroupTouched(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }
  getToday(): string {
    return new Date().toISOString().split('T')[0]
  }
  generateID(number) {
    // If number is less than 10, add a leading zero
    return number < 10 ? '0' + number : '' + number;
  }

}
