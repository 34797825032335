<!-----------      Refraction       ----->
<div class="col-md-12" *ngIf="examRefraction">
  <!-- historique -->
  <div class="card">
    <div class="card-body-historique">
      <h3 class="card-title-historique">Historique Réfraction</h3>
      <div class="arrow arrowBlack btnhistoriquesRefraction mb-3" *ngIf="displayHistorique" title="Cacher la liste des historiques" (click)="displayAndHideHistorique()">▲</div>
      <div class="arrow arrowBlack btnhistoriquesRefraction mb-3" *ngIf="!displayHistorique" title="Afficher la liste des historiques" (click)="displayAndHideHistorique()">▼</div>
      <!-- historique refraction subjective -->
      <div class="row " *ngIf="displayHistorique">
        <div class="menu">
          <button class="btn btnhisRef  cursor-pointer card-title-historique m-l-10" (click)="displayAndHideRefractionSubjective()" [ngClass]="{'active': isActive === 'refractionSubjective'&&displayRefractionSubjectiveHist}">
            Réfraction
          </button>
          <button class=" btn btnhisRef cursor-pointer card-title-historique m-l-10" (click)="displayAndHideAcuiteVisuelleHist()" [ngClass]="{'active': isActive === 'acuiteVisuelleHist'&&displayAcuiteVisuelleHist}">
            Acuité visuel
          </button>
          <button class=" btn btnhisRef  cursor-pointer card-title-historique m-l-10" (click)="displayAndHideRefractionObjective()" [ngClass]="{'active': isActive === 'refractionObjective'&&displayRefractionObjectiveHist}">
            Réfraction finale
          </button>
        </div>

        <div class="menu1" *ngIf="displayRefractionSubjectiveHist">
          <div *ngIf="oldListConsultations.length==0">
            <h6 class="badge-info-dates mt-2">Aucun historique n'est trouvé</h6>
          </div>

          <div class="pagination-container" *ngIf="oldListConsultations.length>0">

            <button class="pagination-arrow" (click)="previousPage()" [disabled]="p === 1">&#8249;</button>
            <ul class="date-list">
              <li class="badge-info-dates" (click)=" openCons(paginatedList[0])" title="Voir détaille de la consultation  {{  paginatedList[0]?.start | date:'dd/MM/yyyy' }}">
                {{ paginatedList[0]?.start | date:'dd/MM/yyyy' }}
              </li>
            </ul>
            <button class="pagination-arrow" (click)="nextPage()" [disabled]="p === oldListConsultations.length">&#8250;</button>
          </div>


          <div class="info mb-3" *ngIf="selectedHistorique">
            <div class="card1 ">
              <div class="card-body-historique">
                <div *ngIf="!selectedHistorique.refraction.avecCycloplegie.present && !selectedHistorique.refraction.sansCycloplegie.present">
                  <h5 class="card-title1">Aucun élément n'est trouvé</h5>
                </div>
                <div *ngIf="selectedHistorique.refraction && selectedHistorique.refraction.sansCycloplegie && selectedHistorique.refraction.sansCycloplegie.present" class="mb-3">
                  <h5 class="card-title1">Sans Cycloplégie</h5>
                  <div class="col-12 sansCyclo">
                    <div class="cadreOeilDroitHist">
                      <div class="oeilTitreSection">
                        <img src="assets/images/oeil.png" alt="icon oeil" class="iconEye" />
                        <h5 id="OeilTitle">Oeil droit</h5>
                      </div>
                      <div id="suboeilTitreSection">
                        <div class="Sphère blocCyclop">

                          <h6>Sphère</h6>
                          <input type="text" class="inputCyclop form-control input-historique" [value]="selectedHistorique.refraction.sansCycloplegie.oielDroit.sphere==undefined?'':selectedHistorique.refraction.sansCycloplegie.oielDroit.sphere" disabled />
                        </div>
                        <div class="cylindre blocCyclop">
                          <h6>Cylindre</h6>
                          <input type="text" class="inputCyclop form-control input-historique" [value]="selectedHistorique.refraction.sansCycloplegie.oielDroit.cylindre==undefined?'':selectedHistorique.refraction.sansCycloplegie.oielDroit.cylindre" disabled />
                        </div>
                        <div class="axe blocCyclop">
                          <h6>Axe</h6>
                          <input type="text" class="inputCyclop form-control input-historique" [value]="selectedHistorique.refraction.sansCycloplegie.oielDroit.axe==undefined?'':selectedHistorique.refraction.sansCycloplegie.oielDroit.axe" disabled />
                        </div>
                        <div class="kératométrie blocCyclop">
                          <h6>kératométrie</h6>
                          <input type="text" class="inputCyclop form-control input-historique" [value]="selectedHistorique.refraction.sansCycloplegie.oielDroit.keratometrie==undefined?'':selectedHistorique.refraction.sansCycloplegie.oielDroit.keratometrie" disabled />
                        </div>
                      </div>
                    </div>
                    <div class="cadreOeilGaucheHist">
                      <div class="oeilTitreSection">
                        <img src="assets/images/oeil.png" alt="icon oeil" class="iconEye" />
                        <h5 id="OeilTitle">Oeil gauche</h5>
                      </div>
                      <div id="suboeilTitreSection">
                        <div class="Sphère blocCyclop">
                          <h6>Sphère</h6>
                          <input type="text" class="inputCyclop form-control input-historique" [value]="selectedHistorique.refraction.sansCycloplegie.oielGauche.sphere==undefined?'':selectedHistorique.refraction.sansCycloplegie.oielGauche.sphere" disabled />
                        </div>
                        <div class="cylindre blocCyclop">
                          <h6>Cylindre</h6>
                          <input type="text" class="inputCyclop form-control input-historique" [value]="selectedHistorique.refraction.sansCycloplegie.oielGauche.cylindre==undefined?'':selectedHistorique.refraction.sansCycloplegie.oielGauche.cylindre" disabled />
                        </div>
                        <div class="axe blocCyclop">
                          <h6>Axe</h6>
                          <input type="text" class="inputCyclop form-control input-historique" [value]="selectedHistorique.refraction.sansCycloplegie.oielGauche.axe==undefined?'':selectedHistorique.refraction.sansCycloplegie.oielGauche.axe" disabled />
                        </div>
                        <div class="kératométrie blocCyclop">
                          <h6>kératométrie</h6>
                          <input type="text" class="inputCyclop form-control input-historique" [value]="selectedHistorique.refraction.sansCycloplegie.oielGauche.keratometrie==undefined?'':selectedHistorique.refraction.sansCycloplegie.oielGauche.keratometrie" disabled />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="selectedHistorique.refraction && selectedHistorique.refraction.avecCycloplegie && selectedHistorique.refraction.avecCycloplegie.present">

                  <h5 class="card-title1 ">Avec Cycloplégie</h5>

                  <div class="col-12 sansCyclo">
                    <div class="cadreOeilDroitHist">
                      <div class="oeilTitreSection">
                        <img src="assets/images/oeil.png" alt="icon oeil" class="iconEye" />
                        <h5 id="OeilTitle">Oeil droit</h5>
                      </div>
                      <div id="suboeilTitreSection">
                        <div class="Sphère blocCyclop">
                          <h6>Sphère</h6>
                          <input type="text" class="inputCyclop form-control input-historique" [value]="selectedHistorique.refraction.avecCycloplegie.oielDroit.sphere==undefined?'':selectedHistorique.refraction.avecCycloplegie.oielDroit.sphere" disabled />
                        </div>
                        <div class="cylindre blocCyclop">
                          <h6>Cylindre</h6>
                          <input type="text" class="inputCyclop form-control input-historique" [value]="selectedHistorique.refraction.avecCycloplegie.oielDroit.cylindre==undefined?'':selectedHistorique.refraction.avecCycloplegie.oielDroit.cylindre" disabled />
                        </div>
                        <div class="axe blocCyclop">
                          <h6>Axe</h6>
                          <input type="text" class="inputCyclop form-control input-historique" [value]="selectedHistorique.refraction.avecCycloplegie.oielDroit.axe==undefined?'':selectedHistorique.refraction.avecCycloplegie.oielDroit.axe" disabled />
                        </div>
                        <div class="kératométrie blocCyclop">
                          <h6>kératométrie</h6>
                          <input type="text" class="inputCyclop form-control input-historique" [value]="selectedHistorique.refraction.avecCycloplegie.oielDroit.keratometrie==undefined?'':selectedHistorique.refraction.avecCycloplegie.oielDroit.keratometrie" disabled />
                        </div>
                      </div>
                    </div>
                    <div class="cadreOeilGaucheHist">
                      <div class="oeilTitreSection">
                        <img src="assets/images/oeil.png" alt="icon oeil" class="iconEye" />
                        <h5 id="OeilTitle">Oeil gauche</h5>
                      </div>
                      <div id="suboeilTitreSection">
                        <div class="Sphère blocCyclop">
                          <h6>Sphère</h6>
                          <input type="text" class="inputCyclop form-control input-historique" [value]="selectedHistorique.refraction.avecCycloplegie.oielGauche.sphere==undefined?'':selectedHistorique.refraction.avecCycloplegie.oielGauche.sphere" disabled />
                        </div>
                        <div class="cylindre blocCyclop">
                          <h6>Cylindre</h6>
                          <input type="text" class="inputCyclop form-control input-historique" [value]="selectedHistorique.refraction.avecCycloplegie.oielGauche.cylindre==undefined?'':selectedHistorique.refraction.avecCycloplegie.oielGauche.cylindre" disabled />
                        </div>
                        <div class="axe blocCyclop">
                          <h6>Axe</h6>
                          <input type="text" class="inputCyclop form-control input-historique" [value]="selectedHistorique.refraction.avecCycloplegie.oielGauche.axe==undefined?'':selectedHistorique.refraction.avecCycloplegie.oielGauche.axe" disabled />
                        </div>
                        <div class="kératométrie blocCyclop">
                          <h6>kératométrie</h6>
                          <input type="text" class="inputCyclop form-control input-historique" [value]="selectedHistorique.refraction.avecCycloplegie.oielGauche.keratometrie==undefined?'':selectedHistorique.refraction.avecCycloplegie.oielGauche.keratometrie" disabled />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
          </div>
        </div>
        <!-- historique Acuité visuel -->

        <div class="row" *ngIf="displayAcuiteVisuelleHist">
          <div class="menu1" *ngIf="oldListConsultations.length==0">
            <h6 class="badge-info-dates mt-2">Aucun historique n'est trouvé</h6>
          </div>

          <div class="subMenu" *ngIf="oldListConsultations.length>0">
            <div class="acuiteMenu cursor-pointer card-title m-l-10" (click)="displayAndHideAcuitevisuelleLoin()" [ngClass]="{'active': isActiveSubMenu === 'acuiteVisuelleHistLoin'}">
              <h6>Acuité visuelle de loin </h6>
            </div>
            <div class="acuiteMenu cursor-pointer card-title m-l-10" (click)="displayAndHideAcuitevisuellePres()" [ngClass]="{'active': isActiveSubMenu === 'acuiteVisuelleHistPres'}">
              <h6>Acuité visuelle de près</h6>
            </div>
          </div>

          <div *ngIf="oldListConsultations.length>0">
            <div *ngIf="selectedAcuiteHistoriqueLoin">
              <canvas baseChart [datasets]="lineChartData" [labels]="lineChartLabels" [options]="lineChartOptions" [legend]="lineChartLegend" [chartType]="lineChartType"></canvas>
            </div>
            <div *ngIf="selectedAcuiteHistoriquePres">
              <canvas baseChart [datasets]="lineChartDataPres" [labels]="lineChartLabels" [options]="lineChartOptionsPres" [legend]="lineChartLegend" [chartType]="lineChartType"></canvas>
            </div>
          </div>
        </div>
        <!-- historique refraction Objective -->

        <div class="menu1" *ngIf="displayRefractionObjectiveHist">
          <div *ngIf="oldListConsultations.length==0">
            <h6 class="badge-info-dates mt-2">Aucun historique n'est trouvé</h6>
          </div>
          <div class="pagination-container" *ngIf="oldListConsultations.length>0">

            <button class="pagination-arrow" (click)="previousPage()" [disabled]="p3 === 1">&#8249;</button>
            <ul class="date-list">
              <li class="badge-info-dates" (click)=" openCons(paginatedList[0])" title="Voir détaille de la consultation  {{  paginatedList[0]?.start | date:'dd/MM/yyyy' }}">
                {{ paginatedList[0]?.start | date:'dd/MM/yyyy' }}
              </li>
            </ul>
            <button class="pagination-arrow" (click)="nextPage()" [disabled]="p3 === oldListConsultations.length">&#8250;</button>
          </div>
          <div class="info mb-3" *ngIf="selectedHistoriqueRefObj">
            <div class="card1">
              <div class="card-body-historique">
                <div *ngIf="!selectedHistoriqueRefObj.refraction.acuitevisueldeloin.present && !selectedHistoriqueRefObj.refraction.acuitevisueldepres.present">
                  <h5 class="card-title1">Aucun élément n'est trouvé</h5>
                </div>
                <div *ngIf="selectedHistoriqueRefObj.refraction && selectedHistoriqueRefObj.refraction.acuitevisueldeloin && selectedHistoriqueRefObj.refraction.acuitevisueldeloin.present" class="mb-3">
                  <h5 class="card-title1">Réfraction finale de loin</h5>
                  <div class="col-12 sansCyclo">
                    <div class="cadreOeilDroitHist">
                      <div class="oeilTitreSection">
                        <img src="assets/images/oeil.png" alt="icon oeil" class="iconEye" />
                        <h5 id="OeilTitle">Oeil droit</h5>
                      </div>
                      <div id="refObjectivSection">
                        <div class="Sphère blocCyclop">
                          <h6>Sphère</h6>
                          <input type="text" class="inputCyclop form-control input-historique" [value]="selectedHistoriqueRefObj.refraction.acuitevisueldeloin.oielDroit.sphere==undefined?'':selectedHistoriqueRefObj.refraction.acuitevisueldeloin.oielDroit.sphere" disabled />
                        </div>
                        <div class="cylindre blocCyclop">
                          <h6>Cylindre</h6>
                          <input type="text" class="inputCyclop form-control input-historique" [value]="selectedHistoriqueRefObj.refraction.acuitevisueldeloin.oielDroit.cylindre==undefined?'':selectedHistoriqueRefObj.refraction.acuitevisueldeloin.oielDroit.cylindre" disabled />
                        </div>
                        <div class="axe blocCyclop">
                          <h6>Axe</h6>
                          <input type="text" class="inputCyclop form-control input-historique" [value]="selectedHistoriqueRefObj.refraction.acuitevisueldeloin.oielDroit.axe==undefined?'':selectedHistoriqueRefObj.refraction.acuitevisueldeloin.oielDroit.axe" disabled />
                        </div>
                      </div>
                    </div>
                    <div class="cadreOeilGaucheHist">
                      <div class="oeilTitreSection">
                        <img src="assets/images/oeil.png" alt="icon oeil" class="iconEye" />
                        <h5 id="OeilTitle">Oeil gauche</h5>
                      </div>
                      <div id="refObjectivSection">
                        <div class="Sphère blocCyclop">
                          <h6>Sphère</h6>
                          <input type="text" class="inputCyclop form-control input-historique" [value]="selectedHistoriqueRefObj.refraction.acuitevisueldeloin.oielGauche.sphere==undefined?'':selectedHistoriqueRefObj.refraction.acuitevisueldeloin.oielGauche.sphere" disabled />
                        </div>
                        <div class="cylindre blocCyclop">
                          <h6>Cylindre</h6>
                          <input type="text" class="inputCyclop form-control input-historique" [value]="selectedHistoriqueRefObj.refraction.acuitevisueldeloin.oielGauche.cylindre==undefined?'':selectedHistoriqueRefObj.refraction.acuitevisueldeloin.oielGauche.cylindre" disabled />
                        </div>
                        <div class="axe blocCyclop">
                          <h6>Axe</h6>
                          <input type="text" class="inputCyclop form-control input-historique" [value]="selectedHistoriqueRefObj.refraction.acuitevisueldeloin.oielGauche.axe==undefined?'':selectedHistoriqueRefObj.refraction.acuitevisueldeloin.oielGauche.axe" disabled />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="selectedHistoriqueRefObj.refraction && selectedHistoriqueRefObj.refraction.acuitevisueldepres && selectedHistoriqueRefObj.refraction.acuitevisueldepres.present">

                  <h5 class="card-title1 ">Réfraction finale de près</h5>

                  <div class="col-12 sansCyclo">
                    <div class="cadreOeilDroitHist">
                      <div class="oeilTitreSection">
                        <img src="assets/images/oeil.png" alt="icon oeil" class="iconEye" />
                        <h5 id="OeilTitle">Oeil droit</h5>
                      </div>
                      <div id="refObjectivSection">
                        <div class="Sphère blocCyclop">
                          <h6>Sphère</h6>
                          <input type="text" class="inputCyclop form-control input-historique" [value]="selectedHistoriqueRefObj.refraction.acuitevisueldepres.oielDroit.sphere==undefined?'':selectedHistoriqueRefObj.refraction.acuitevisueldepres.oielDroit.sphere" disabled />
                        </div>
                        <div class="cylindre blocCyclop">
                          <h6>Cylindre</h6>
                          <input type="text" class="inputCyclop form-control input-historique" [value]="selectedHistoriqueRefObj.refraction.acuitevisueldepres.oielDroit.cylindre==undefined?'':selectedHistoriqueRefObj.refraction.acuitevisueldepres.oielDroit.cylindre" disabled />
                        </div>
                        <div class="axe blocCyclop">
                          <h6>Axe</h6>
                          <input type="text" class="inputCyclop form-control input-historique" [value]="selectedHistoriqueRefObj.refraction.acuitevisueldepres.oielDroit.axe==undefined?'':selectedHistoriqueRefObj.refraction.acuitevisueldepres.oielDroit.axe" disabled />
                        </div>
                      </div>
                    </div>
                    <div class="cadreOeilGaucheHist">
                      <div class="oeilTitreSection">
                        <img src="assets/images/oeil.png" alt="icon oeil" class="iconEye" />
                        <h5 id="OeilTitle">Oeil gauche</h5>
                      </div>
                      <div id="refObjectivSection">
                        <div class="Sphère blocCyclop">
                          <h6>Sphère</h6>
                          <input type="text" class="inputCyclop form-control input-historique" [value]="selectedHistoriqueRefObj.refraction.acuitevisueldepres.oielGauche.sphere==undefined?'':selectedHistoriqueRefObj.refraction.acuitevisueldepres.oielGauche.sphere" disabled />
                        </div>
                        <div class="cylindre blocCyclop">
                          <h6>Cylindre</h6>
                          <input type="text" class="inputCyclop form-control input-historique" [value]="selectedHistoriqueRefObj.refraction.acuitevisueldepres.oielGauche.cylindre==undefined?'':selectedHistoriqueRefObj.refraction.acuitevisueldepres.oielGauche.cylindre" disabled />
                        </div>
                        <div class="axe blocCyclop">
                          <h6>Axe</h6>
                          <input type="text" class="inputCyclop form-control input-historique" [value]="selectedHistoriqueRefObj.refraction.acuitevisueldepres.oielGauche.axe==undefined?'':selectedHistoriqueRefObj.refraction.acuitevisueldepres.oielGauche.axe" disabled />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>


      </div>
    </div>
  </div>
  <!-- end of historique -->
  <br>
  <div class="card ">
    <div class="card-body">
      <h3 class="card-title-historique">Examen d'aujourd'hui</h3>
      <div class="arrow arrowBlack btnhistoriquesRefraction mb-3" *ngIf="todayExamenRef" title="Cacher examen d'aujourd'hui" (click)="displayAndHidetodayExamen()">▲</div>
      <div class="arrow arrowBlack btnhistoriquesRefraction mb-3" *ngIf="!todayExamenRef" title="Afficher examen d'aujourd'hui" (click)="displayAndHidetodayExamen()">▼</div>
      <br />
      <div *ngIf="todayExamenRef">
        <!-- Réfraction -->
        <div>
          <div id="refractionSubj">
            <h3 class="card-title-historique">Réfraction</h3>
            <div class="arrow arrowBlack btnRefraction mb-3" *ngIf="showRefSubjective" title="Cacher Réfraction" (click)="showHideRefractionSubjective()">▲</div>
            <div class="arrow arrowBlack btnRefraction mb-3" *ngIf="!showRefSubjective" title="Afficher Réfraction" (click)="showHideRefractionSubjective()">▼</div>
            <div *ngIf="showRefSubjective">
              <label class="switch">
                <input type="checkbox" [(ngModel)]="examRefraction.refraction.sansCycloplegie.present" />
                <span class="slider round"></span>
              </label>

              <p id="textswitchtoggle">Sans Cycloplégie</p>
              <img src="assets/images/rotate-Nobg.png" title="Récupérer" (click)="getRefractionSansCycDatafromFile()" style="width: 28px;position: relative;left: 8px;cursor: pointer;background-color: transparent;">
              <div *ngIf="examRefraction.refraction.sansCycloplegie.present">
                <br />
                <div class="col-12 sansCyclo">
                  <div class="cadreOeilDroit">
                    <div class="blocErreurRefractif">
                      <div class="checkBoxbloc mt-1">
                        <input type="checkbox" class="radiotypeinput" id="checkbox" [(ngModel)]="examRefraction.refraction.sansCycloplegie.erreurRefractif" />
                        <p class="ACcheckboxLabel">Erreur réfractive</p>
                      </div>
                      <div class="oeilTitreSection" style="margin-left: -9rem;">
                        <img src="assets/images/oeil.png" alt="icon oeil" class="iconEye" />
                        <h5 id="OeilTitle">Oeil droit</h5>
                      </div>
                    </div>
                    <div id="suboeilTitreSection">
                      <!-- !Sphére Sans cyclo -->
                      <div class="Sphère blocCyclop">
                        <h6>Sphère</h6>
                        <div class="noteDroit">
                          <input type="text" maxlength="10" class="inputCyclop form-control input-refraction" [(ngModel)]="examRefraction.refraction.sansCycloplegie.oielDroit.sphere" (change)="changeSansCycloSphereDroit($event.target.value)" onkeypress="return /[a-zA-Z0-9 .+-]/.test(event.key)" [disabled]="examRefraction.refraction.sansCycloplegie.erreurRefractif" />
                          <div class="number-input " *ngIf="!examRefraction.refraction.sansCycloplegie.erreurRefractif">
                            <div class="arrow arrow-up" (click)="incrementscODSph()">▲</div>
                            <br />
                            <div class="arrow arrow-down" (click)="decrementscODSph()">▼</div>
                          </div>
                        </div>
                      </div>
                      <!-- !end  of Sphére Sans cyclo -->
                      <!-- !cylindre Sans cyclo -->
                      <div class="cylindre blocCyclop">
                        <h6>Cylindre</h6>
                        <div class="noteDroit">
                          <input type="text" maxlength="10" class="inputCyclop form-control input-refraction" [(ngModel)]="examRefraction.refraction.sansCycloplegie.oielDroit.cylindre" (change)="changeSansCycloCylindreDroit($event.target.value)" onkeypress="return /[a-zA-Z0-9 .+-]/.test(event.key)" [disabled]="examRefraction.refraction.sansCycloplegie.erreurRefractif" />
                          <div class="number-input" *ngIf="!examRefraction.refraction.sansCycloplegie.erreurRefractif">
                            <div class="arrow arrow-up" (click)="incrementscODCy()">▲</div>
                            <br />
                            <div class="arrow arrow-down" (click)="decrementscODCy()">▼</div>
                          </div>
                        </div>
                      </div>
                      <!-- !end od cylindre Sans cyclo -->
                      <!-- !axe Sans cyclo -->
                      <div class="axe blocCyclop">
                        <h6>Axe</h6>

                        <div class="noteDroit">
                          <input type="text" maxlength="10" class="inputCyclop form-control input-refraction" [(ngModel)]="examRefraction.refraction.sansCycloplegie.oielDroit.axe" (change)="changeScODAxe($event.target.value)" onkeypress="return /[a-zA-Z0-9 .]/.test(event.key)" [disabled]="examRefraction.refraction.sansCycloplegie.erreurRefractif" />
                          <div class="number-input" *ngIf="!examRefraction.refraction.sansCycloplegie.erreurRefractif">
                            <div class="arrow arrow-up" (click)="incrementScODAxe()">▲</div>
                            <br />
                            <div class="arrow arrow-down" (click)="decrementScODAxe()">▼</div>
                          </div>
                        </div>
                      </div>
                      <!-- !end of axe Sans cyclo -->
                      <!-- !kératométrie Sans cyclo -->
                      <div class="kératométrie blocCyclop ">
                        <h6>kératométrie</h6>
                        <div class="noteDroit">
                          <input type="text" maxlength="10" class="inputCyclop form-control input-refraction" [(ngModel)]="examRefraction.refraction.sansCycloplegie.oielDroit.keratometrie" (change)="changeScODKera($event.target.value)" onkeypress="return /[a-zA-Z0-9 .]/.test(event.key)" [disabled]="examRefraction.refraction.sansCycloplegie.erreurRefractif" />
                          <div class="number-input" *ngIf="!examRefraction.refraction.sansCycloplegie.erreurRefractif">
                            <div class="arrow arrow-up" (click)="incrementScODKera()">▲</div>
                            <br />
                            <div class="arrow arrow-down" (click)="decrementScODKera()">▼</div>
                          </div>
                        </div>
                      </div>
                      <!-- !end of kératométrie Sans cyclo -->
                    </div>
                  </div>
                  <div class="cadreOeilGauche">
                    <div class="oeilTitreSection mt-1">
                      <img src="assets/images/oeil.png" alt="icon oeil" class="iconEye" />
                      <h5 id="OeilTitle">Oeil gauche</h5>
                    </div>
                    <div id="suboeilTitreSection">
                      <!-- !Sphére Sans cyclo -->
                      <div class="Sphère blocCyclop">
                        <h6>Sphère</h6>
                        <div class="noteDroit">
                          <input type="text" maxlength="10" class="inputCyclop form-control input-refraction" [(ngModel)]="examRefraction.refraction.sansCycloplegie.oielGauche.sphere" (change)="changeSansCycloSphereGauche($event.target.value)" onkeypress="return /[a-zA-Z0-9 .+-]/.test(event.key)" [disabled]="examRefraction.refraction.sansCycloplegie.erreurRefractif" />
                          <div class="number-input" *ngIf="!examRefraction.refraction.sansCycloplegie.erreurRefractif">
                            <div class="arrow arrow-up" (click)="incrementscOGSph()">▲</div>
                            <br />
                            <div class="arrow arrow-down" (click)="decrementscOGSph()">▼</div>
                          </div>
                        </div>
                      </div>
                      <!-- !end  of Sphére Sans cyclo -->
                      <!-- !cylindre Sans cyclo -->
                      <div class="cylindre blocCyclop">
                        <h6>Cylindre</h6>
                        <div class="noteDroit">
                          <input type="text" maxlength="10" class="inputCyclop form-control input-refraction" [(ngModel)]="examRefraction.refraction.sansCycloplegie.oielGauche.cylindre" (change)="changeSansCycloCylindreGauche($event.target.value)" onkeypress="return /[a-zA-Z0-9 .+-]/.test(event.key)" [disabled]="examRefraction.refraction.sansCycloplegie.erreurRefractif" />
                          <div class="number-input" *ngIf="!examRefraction.refraction.sansCycloplegie.erreurRefractif">
                            <div class="arrow arrow-up" (click)="incrementscOGCy()">▲</div>
                            <br />
                            <div class="arrow arrow-down" (click)="decrementscOGCy()">▼</div>
                          </div>
                        </div>
                      </div>
                      <!-- !end of cylindre Sans cyclo -->
                      <!-- !axe Sans cyclo -->
                      <div class="axe blocCyclop">
                        <h6>Axe</h6>
                        <div class="noteDroit">
                          <input type="text" maxlength="10" class="inputCyclop form-control input-refraction" [(ngModel)]="examRefraction.refraction.sansCycloplegie.oielGauche.axe" (change)="changeScOGAxe($event.target.value)" onkeypress="return /[a-zA-Z0-9 .]/.test(event.key)" [disabled]="examRefraction.refraction.sansCycloplegie.erreurRefractif" />
                          <div class="number-input" *ngIf="!examRefraction.refraction.sansCycloplegie.erreurRefractif">
                            <div class="arrow arrow-up" (click)="incrementScOGAxe()">▲</div>
                            <br />
                            <div class="arrow arrow-down" (click)="decrementScOGAxe()">▼</div>
                          </div>
                        </div>
                      </div>
                      <!-- !end of axe Sans cyclo -->
                      <!-- !kératométrie Sans cyclo -->
                      <div class="kératométrie blocCyclop ">
                        <h6>kératométrie</h6>
                        <div class="noteDroit">
                          <input type="text" maxlength="10" class="inputCyclop form-control input-refraction" [(ngModel)]="examRefraction.refraction.sansCycloplegie.oielGauche.keratometrie" (change)="changeScOGKera($event.target.value)" onkeypress="return /[a-zA-Z0-9 .]/.test(event.key)" [disabled]="examRefraction.refraction.sansCycloplegie.erreurRefractif" />
                          <div class="number-input" *ngIf="!examRefraction.refraction.sansCycloplegie.erreurRefractif">
                            <div class="arrow arrow-up" (click)="incrementScOGKera()">▲</div>
                            <br />
                            <div class="arrow arrow-down" (click)="decrementScOGKera()">▼</div>
                          </div>
                        </div>
                      </div>
                      <!-- !end of kératométrie Sans cyclo -->
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <!-- avec cyclopégie section -->
              <label class="switch">
                <input type="checkbox" [(ngModel)]="examRefraction.refraction.avecCycloplegie.present" />
                <span class="slider round"></span>
              </label>

              <p id="textswitchtoggle">Avec Cycloplégie</p>
              <img src="assets/images/rotate-Nobg.png" title="Récupérer" (click)="getRefractionAvecCycDatafromFile()" style="width: 28px;position: relative;left: 8px;cursor: pointer;background-color: transparent;">
              <br />
              <div *ngIf="examRefraction.refraction.avecCycloplegie.present">
                <div id="avecCyclopCheckboxex">
                  <div class="checkBoxbloc">
                    <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examRefraction.refraction.avecCycloplegie.type==='atropine'" (change)="selectAvecCyclopAtropine()" />
                    <p class="ACcheckboxLabel">Atropine</p>
                  </div>
                  <div class="checkBoxbloc">
                    <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examRefraction.refraction.avecCycloplegie.type==='cyclocol'" (change)="selectAvecCyclopCyclocol()" />
                    <p class="ACcheckboxLabel">Cyclocol</p>
                  </div>
                  <div class="checkBoxbloc">
                    <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examRefraction.refraction.avecCycloplegie.type==='tropicamide'" (change)="selectAvecCyclopTropicamide()" />
                    <p class="ACcheckboxLabel">Tropicamide</p>
                  </div>
                </div>
                <div class="col-12 sansCyclo">
                  <div class="cadreOeilDroit">
                    <div class="blocErreurRefractif">
                      <div class="checkBoxbloc mt-1">
                        <input type="checkbox" class="radiotypeinput" id="checkbox" [(ngModel)]="examRefraction.refraction.avecCycloplegie.erreurRefractif" />
                        <p class="ACcheckboxLabel">Erreur réfractive</p>
                      </div>
                      <div class="oeilTitreSection" style="margin-left: -9rem;">
                        <img src="assets/images/oeil.png" alt="icon oeil" class="iconEye" />
                        <h5 id="OeilTitle">Oeil droit</h5>
                      </div>
                    </div>

                    <div id="suboeilTitreSection">
                      <!-- !Sphére avec cyclo -->
                      <div class="Sphère blocCyclop">
                        <h6>Sphère</h6>
                        <div class="noteDroit">
                          <input type="text" maxlength="10" class="inputCyclop form-control input-refraction" [(ngModel)]="examRefraction.refraction.avecCycloplegie.oielDroit.sphere" (change)="changeAvecCycloSphereDroit($event.target.value)" onkeypress="return /[a-zA-Z0-9 .+-]/i.test(event.key)" [disabled]="examRefraction.refraction.avecCycloplegie.erreurRefractif" />
                          <div class="number-input" *ngIf="!examRefraction.refraction.avecCycloplegie.erreurRefractif">
                            <div class="arrow arrow-up" (click)="incrementAcODSph()">▲</div>
                            <br />
                            <div class="arrow arrow-down" (click)="decrementAcODSph()">▼</div>
                          </div>
                        </div>
                      </div>
                      <!-- !end  of Sphére avec cyclo -->
                      <!-- !cylindre avec cyclo -->
                      <div class="cylindre blocCyclop">
                        <h6>Cylindre</h6>
                        <div class="noteDroit">
                          <input type="text" maxlength="10" class="inputCyclop form-control input-refraction" [(ngModel)]="examRefraction.refraction.avecCycloplegie.oielDroit.cylindre" (change)="changeAvecCycloCylindreDroit($event.target.value)" onkeypress="return /[a-zA-Z0-9 .+-]/i.test(event.key)" [disabled]="examRefraction.refraction.avecCycloplegie.erreurRefractif" />
                          <div class="number-input" *ngIf="!examRefraction.refraction.avecCycloplegie.erreurRefractif">
                            <div class="arrow arrow-up" (click)="incrementAcODCy()">▲</div>
                            <br />
                            <div class="arrow arrow-down" (click)="decrementAcODCy()">▼</div>
                          </div>
                        </div>
                      </div>
                      <!-- !end od cylindre avec cyclo -->
                      <!-- !axe avec cyclo -->
                      <div class="axe blocCyclop">
                        <h6>Axe</h6>

                        <div class="noteDroit">
                          <input type="text" maxlength="10" class="inputCyclop form-control input-refraction" [(ngModel)]="examRefraction.refraction.avecCycloplegie.oielDroit.axe" (change)="changeAcODAxe($event.target.value)" onkeypress="return /[a-zA-Z0-9 .]/i.test(event.key)" [disabled]="examRefraction.refraction.avecCycloplegie.erreurRefractif" />
                          <div class="number-input" *ngIf="!examRefraction.refraction.avecCycloplegie.erreurRefractif">
                            <div class="arrow arrow-up" (click)="incrementAcODAxe()">▲</div>
                            <br />
                            <div class="arrow arrow-down" (click)="decrementAcODAxe()">▼</div>
                          </div>
                        </div>
                      </div>
                      <!-- !end of axe avec cyclo -->
                      <!-- !kératométrie avec cyclo -->
                      <div class="kératométrie blocCyclop ">
                        <h6>kératométrie</h6>
                        <div class="noteDroit">
                          <input type="text" maxlength="10" class="inputCyclop form-control input-refraction" [(ngModel)]="examRefraction.refraction.avecCycloplegie.oielDroit.keratometrie" (change)="changeAcODKera($event.target.value)" onkeypress="return /[a-zA-Z0-9 .+]/i.test(event.key)" [disabled]="examRefraction.refraction.avecCycloplegie.erreurRefractif" />
                          <div class="number-input" *ngIf="!examRefraction.refraction.avecCycloplegie.erreurRefractif">
                            <div class="arrow arrow-up" (click)="incrementAcODKera()">▲</div>
                            <br />
                            <div class="arrow arrow-down" (click)="decrementAcODKera()">▼</div>
                          </div>
                        </div>
                      </div>
                      <!-- !end of kératométrie avec cyclo -->
                    </div>
                  </div>
                  <div class="cadreOeilGauche">
                    <div class="oeilTitreSection mt-1">
                      <img src="assets/images/oeil.png" alt="icon oeil" class="iconEye" />
                      <h5 id="OeilTitle">Oeil gauche</h5>
                    </div>
                    <div id="suboeilTitreSection">
                      <!-- !Sphére avec cyclo -->
                      <div class="Sphère blocCyclop">
                        <h6>Sphère</h6>
                        <div class="noteDroit">
                          <input type="text" maxlength="10" class="inputCyclop form-control input-refraction" [(ngModel)]="examRefraction.refraction.avecCycloplegie.oielGauche.sphere" (change)="changeAvecCycloSphereGauche($event.target.value)" onkeypress="return /[a-zA-Z0-9 .+-]/i.test(event.key)" [disabled]="examRefraction.refraction.avecCycloplegie.erreurRefractif" />
                          <div class="number-input" *ngIf="!examRefraction.refraction.avecCycloplegie.erreurRefractif">
                            <div class="arrow arrow-up" (click)="incrementAcOGSph()">▲</div>
                            <br />
                            <div class="arrow arrow-down" (click)="decrementAcOGSph()">▼</div>
                          </div>
                        </div>
                      </div>
                      <!-- !end  of Sphére avec cyclo -->
                      <!-- !cylindre avec cyclo -->
                      <div class="cylindre blocCyclop">
                        <h6>Cylindre</h6>
                        <div class="noteDroit">
                          <input type="text" maxlength="10" class="inputCyclop form-control input-refraction" [(ngModel)]="examRefraction.refraction.avecCycloplegie.oielGauche.cylindre" (change)="changeAvecCycloCylindreGauche($event.target.value)" onkeypress="return /[a-zA-Z0-9 .+-]/i.test(event.key)" [disabled]="examRefraction.refraction.avecCycloplegie.erreurRefractif" />
                          <div class="number-input" *ngIf="!examRefraction.refraction.avecCycloplegie.erreurRefractif">
                            <div class="arrow arrow-up" (click)="incrementAcOGCy()">▲</div>
                            <br />
                            <div class="arrow arrow-down" (click)="decrementAcOGCy()">▼</div>
                          </div>
                        </div>
                      </div>
                      <!-- !end of cylindre avec cyclo -->
                      <!-- !axe avec cyclo -->
                      <div class="axe blocCyclop">
                        <h6>Axe</h6>
                        <div class="noteDroit">
                          <input type="text" maxlength="10" class="inputCyclop form-control input-refraction" [(ngModel)]="examRefraction.refraction.avecCycloplegie.oielGauche.axe" (change)="changeAcOGAxe($event.target.value)" onkeypress="return /[a-zA-Z0-9 .]/i.test(event.key)" [disabled]="examRefraction.refraction.avecCycloplegie.erreurRefractif" />
                          <div class="number-input" *ngIf="!examRefraction.refraction.avecCycloplegie.erreurRefractif">
                            <div class="arrow arrow-up" (click)="incrementAcOGAxe()">▲</div>
                            <br />
                            <div class="arrow arrow-down" (click)="decrementAcOGAxe()">▼</div>
                          </div>
                        </div>
                      </div>
                      <!-- !end of axe avec cyclo -->
                      <!-- !kératométrie avec cyclo -->
                      <div class="kératométrie blocCyclop ">
                        <h6>kératométrie</h6>
                        <div class="noteDroit">
                          <input type="text" maxlength="10" class="inputCyclop form-control input-refraction" [(ngModel)]="examRefraction.refraction.avecCycloplegie.oielGauche.keratometrie" (change)="changeAcOGKera($event.target.value)" onkeypress="return /[a-zA-Z0-9 .+]/i.test(event.key)" [disabled]="examRefraction.refraction.avecCycloplegie.erreurRefractif" />
                          <div class="number-input" *ngIf="!examRefraction.refraction.avecCycloplegie.erreurRefractif">
                            <div class="arrow arrow-up" (click)="incrementAcOGKera()">▲</div>
                            <br />
                            <div class="arrow arrow-down" (click)="decrementAcOGKera()">▼</div>
                          </div>
                        </div>
                      </div>
                      <!-- !end of kératométrie avec cyclo -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- sans cyclopégie section -->
          </div>
        </div>


        <br />
        <!-- Acuité visuel + Réfraction finale -->
        <div>
          <div id="acuiteVisuelle">
            <h3 class="card-title-historique">Acuité Visuel</h3>
            <div class="arrow arrowBlack btnAcuite mb-3" *ngIf="showAcuite" title="Cacher Acuité Visuel" (click)="showHideAcuite()">▲</div>
            <div class="arrow arrowBlack btnAcuite mb-3" *ngIf="!showAcuite" title="Afficher Acuité Visuel" (click)="showHideAcuite()">▼</div>
            <div *ngIf="showAcuite">
              <label class="switch">
                <input type="checkbox" [(ngModel)]="examRefraction.refraction.acuitevisueldeloin.present" />
                <span class="slider round"></span>
              </label>
              <p id="textswitchtoggle">Acuité visuel de loin</p>
              <div *ngIf="examRefraction.refraction.acuitevisueldeloin.present">
                <div id="acuiteVisLoin">
                  <br />
                  <div id="headerAcuiteLoin">
                    <h6>Échelle :</h6>
                    <div>
                      <input type="checkbox" class="checkEchelleAcuit" [checked]="examRefraction.refraction.acuitevisueldeloin.echelle==='monoyer'" (change)="selectEchelleMonoyer()" />
                      <label>Monoyer</label>
                    </div>
                    <div>
                      <input type="checkbox" class="checkEchelleAcuit" [checked]="examRefraction.refraction.acuitevisueldeloin.echelle==='snellen'" (change)="selectEchelleSnellen()" />
                      <label>Snellen</label>
                    </div>

                  </div>
                  <!-- echelle Monoyer -->
                  <div *ngIf="examRefraction.refraction.acuitevisueldeloin.echelle==='monoyer'">
                    <div id="noteAVSC">
                      <div class="noteDroit">
                        <select class="form-control input-refraction" [(ngModel)]="examRefraction.refraction.acuitevisueldeloin.oielDroit.avsc">
                          <option *ngFor="let element of echelleMonoyerList" [value]="element">{{element}}</option>
                        </select>
                      </div>
                      <h4 id="colorBlack">AVSC</h4>
                      <div class="noteDroit">
                        <select class="form-control input-refraction" [(ngModel)]="examRefraction.refraction.acuitevisueldeloin.oielGauche.avsc">
                          <option *ngFor="let element of echelleMonoyerList" [value]="element">{{element}}</option>
                        </select>
                      </div>
                    </div>
                    <div id="noteAVSC">
                      <div class="noteDroit">
                        <select class="form-control input-refraction" [(ngModel)]="examRefraction.refraction.acuitevisueldeloin.oielDroit.avac">
                          <option *ngFor="let element of echelleMonoyerList" [value]="element">{{element}}</option>
                        </select>
                      </div>
                      <h4 id="colorBlack">AVAC</h4>
                      <div class="noteDroit">
                        <select class="form-control input-refraction" [(ngModel)]="examRefraction.refraction.acuitevisueldeloin.oielGauche.avac">
                          <option *ngFor="let element of echelleMonoyerList" [value]="element">{{element}}</option>
                        </select>
                      </div>
                    </div>
                    <!-- Réfraction finale -->
                    <div>
                      <h4 id="colorBlack" class="text-center">Réfraction finale</h4>
                      <div [ngClass]="previousConsultation?'col-12 sansCycloBlocRef':'col-12 sansCyclo'">
                        <div class="cadreAcuiteOeilDroit">
                          <div class="oeilTitreSection">
                            <img src="assets/images/oeil.png" alt="icon oeil" class="iconEye" />
                            <h5 id="OeilTitle1">Oeil droit</h5>
                          </div>
                          <div id="refObjectivSection">
                            <!-- !Sphére -->
                            <div class="Sphère blocCyclop">
                              <h6>Sphère</h6>
                              <div class="noteDroit">
                                <input type="text" maxlength="10" class="inputCyclop form-control input-refraction" [(ngModel)]="examRefraction.refraction.acuitevisueldeloin.oielDroit.sphere" (change)="changeLoinODSph($event.target.value)" onkeypress="return /[a-zA-Z0-9 .+-]/i.test(event.key)" />
                                <div class="number-input mr-1">
                                  <div class="arrow arrow-up" (click)="incrementLoinODSph()">▲</div>
                                  <br />
                                  <div class="arrow arrow-down" (click)="decrementLoinODSph()">▼</div>
                                </div>
                              </div>
                            </div>
                            <!-- !end  of Sphére -->
                            <!-- !cylindre -->
                            <div class="cylindre blocCyclop">
                              <h6>Cylindre</h6>
                              <div class="noteDroit">
                                <input type="text" maxlength="10" class="inputCyclop form-control input-refraction" [(ngModel)]="examRefraction.refraction.acuitevisueldeloin.oielDroit.cylindre" (change)="changeLoinLoinODCy($event.target.value)" onkeypress="return /[a-zA-Z0-9 .+-]/i.test(event.key)" />
                                <div class="number-input">
                                  <div class="arrow arrow-up" (click)="incrementLoinODCy()">▲</div>
                                  <br />
                                  <div class="arrow arrow-down" (click)="decrementLoinODCy()">▼</div>
                                </div>
                              </div>
                            </div>
                            <!-- !end od cylindre -->
                            <!-- !axe -->
                            <div class="axe blocCyclop">
                              <h6>Axe</h6>

                              <div class="noteDroit">
                                <input type="text" maxlength="10" class="inputCyclop form-control input-refraction" [(ngModel)]="examRefraction.refraction.acuitevisueldeloin.oielDroit.axe" (change)="changeLoinODAxe($event.target.value)" onkeypress="return /[a-zA-Z0-9 .]/i.test(event.key)" />
                                <div class="number-input">
                                  <div class="arrow arrow-up" (click)="incrementLoinODAxe()">▲</div>
                                  <br />
                                  <div class="arrow arrow-down" (click)="decrementLoinODAxe()">▼</div>
                                </div>
                              </div>
                            </div>
                            <!-- !end of axe -->
                            <!-- bloc RV -->
                            <div class="checkBoxrefObjectiv">
                              <input type="checkbox" class="radiotypeinput" [checked]="examRefraction.refraction.acuitevisueldeloin.oielDroit.rv==='R < V'" (change)="selectRVOeilDroitLoin('R < V')" />
                              <p class="ACcheckboxLabel">R < V </p>
                            </div>
                            <div class="checkBoxrefObjectiv">
                              <input type="checkbox" class="radiotypeinput" [checked]="examRefraction.refraction.acuitevisueldeloin.oielDroit.rv==='R = V'" (change)="selectRVOeilDroitLoin('R = V')" />
                              <p class="ACcheckboxLabel">R = V </p>
                            </div>
                            <div class="checkBoxrefObjectiv">
                              <input type="checkbox" class="radiotypeinput" [checked]="examRefraction.refraction.acuitevisueldeloin.oielDroit.rv==='R > V'" (change)="selectRVOeilDroitLoin('R > V')" />
                              <p class="ACcheckboxLabel">R > V </p>
                            </div>
                            <!-- bloc RV -->
                            <!---------------- ancien consultaion -->
                            <!-- !Sphére -->
                            <div class="Sphère blocCyclop" *ngIf="previousConsultation">
                              <h6>Sphère</h6>
                              <div class="noteDroit">
                                <input type="text" maxlength="10" class="inputCyclop form-control input-refraction" [(ngModel)]="previousConsultation.refraction.acuitevisueldeloin.oielGauche.sphere" disabled />
                              </div>
                            </div>
                            <!-- !end  of Sphére -->
                            <!-- !cylindre -->
                            <div class="cylindre blocCyclop" *ngIf="previousConsultation">
                              <h6>Cylindre</h6>
                              <div class="noteDroit">
                                <input type="text" maxlength="10" class="inputCyclop form-control input-refraction" [(ngModel)]="previousConsultation.refraction.acuitevisueldeloin.oielGauche.cylindre" disabled />
                              </div>
                            </div>
                            <!-- !end od cylindre -->
                            <!-- !axe -->
                            <div class="axe blocCyclop" *ngIf="previousConsultation">
                              <h6>Axe</h6>
                              <div class="noteDroit">
                                <input type="text" maxlength="10" class="inputCyclop form-control input-refraction" [(ngModel)]="previousConsultation.refraction.acuitevisueldeloin.oielGauche.axe" disabled />
                              </div>
                            </div>
                            <!-- !end of axe -->
                            <!----------------end ancien consultaion -->
                          </div>
                        </div>
                        <h5 id="ancienneConsult" *ngIf="previousConsultation">Ancienne consultation</h5>
                        <div class="cadreAcuiteOeilGauche">
                          <div class="oeilTitreSection">
                            <img src="assets/images/oeil.png" alt="icon oeil" class="iconEye" />
                            <h5 id="OeilTitle1">Oeil gauche</h5>
                          </div>
                          <div id="refObjectivSection">
                            <!-- !Sphére -->
                            <div class="Sphère blocCyclop">
                              <h6>Sphère</h6>
                              <div class="noteDroit">
                                <input type="text" maxlength="10" class="inputCyclop form-control input-refraction" [(ngModel)]="examRefraction.refraction.acuitevisueldeloin.oielGauche.sphere" (change)="changeLoinLoinOGSph($event.target.value)" onkeypress="return /[a-zA-Z0-9 .+-]/i.test(event.key)" />
                                <div class="number-input">
                                  <div class="arrow arrow-up" (click)="incrementLoinOGSph()">▲</div>
                                  <br />
                                  <div class="arrow arrow-down" (click)="decrementLoinOGSph()">▼</div>
                                </div>
                              </div>
                            </div>
                            <!-- !end  of Sphére -->
                            <!-- !cylindre -->
                            <div class="cylindre blocCyclop">
                              <h6>Cylindre</h6>
                              <div class="noteDroit">
                                <input type="text" maxlength="10" class="inputCyclop form-control input-refraction" [(ngModel)]="examRefraction.refraction.acuitevisueldeloin.oielGauche.cylindre" (change)="changeLoinOGCy($event.target.value)" onkeypress="return /[a-zA-Z0-9 .+-]/i.test(event.key)" />
                                <div class="number-input">
                                  <div class="arrow arrow-up" (click)="incrementLoinOGCy()">▲</div>
                                  <br />
                                  <div class="arrow arrow-down" (click)="decrementLoinOGCy()">▼</div>
                                </div>
                              </div>
                            </div>
                            <!-- !end of cylindre -->
                            <!-- !axe -->
                            <div class="axe blocCyclop">
                              <h6>Axe</h6>
                              <div class="noteDroit">
                                <input type="text" maxlength="10" class="inputCyclop form-control input-refraction" [(ngModel)]="examRefraction.refraction.acuitevisueldeloin.oielGauche.axe" (change)="changeLoinOGAxe($event.target.value)" onkeypress="return /[a-zA-Z0-9 .]/i.test(event.key)" />
                                <div class="number-input">
                                  <div class="arrow arrow-up" (click)="incrementLoinOGAxe()">▲</div>
                                  <br />
                                  <div class="arrow arrow-down" (click)="decrementLoinOGAxe()">▼</div>
                                </div>
                              </div>
                            </div>
                            <!-- !end of axe -->
                            <!-- bloc RV -->
                            <div class="checkBoxrefObjectiv">
                              <input type="checkbox" class="radiotypeinput" [checked]="examRefraction.refraction.acuitevisueldeloin.oielGauche.rv==='R < V'" (change)="selectRVOeilGaucheLoin('R < V')" />
                              <p class="ACcheckboxLabel">R < V </p>
                            </div>
                            <div class="checkBoxrefObjectiv">
                              <input type="checkbox" class="radiotypeinput" [checked]="examRefraction.refraction.acuitevisueldeloin.oielGauche.rv==='R = V'" (change)="selectRVOeilGaucheLoin('R = V')" />
                              <p class="ACcheckboxLabel">R = V </p>
                            </div>
                            <div class="checkBoxrefObjectiv">
                              <input type="checkbox" class="radiotypeinput" [checked]="examRefraction.refraction.acuitevisueldeloin.oielGauche.rv==='R > V'" (change)="selectRVOeilGaucheLoin('R > V')" />
                              <p class="ACcheckboxLabel">R > V </p>
                            </div>
                            <!-- bloc RV -->
                            <!---------------- ancien consultaion -->
                            <!-- !Sphére -->
                            <div class="Sphère blocCyclop" *ngIf="previousConsultation">
                              <h6>Sphère</h6>
                              <div class="noteDroit">
                                <input type="text" maxlength="10" class="inputCyclop form-control input-refraction" [(ngModel)]="previousConsultation.refraction.acuitevisueldeloin.oielDroit.sphere" disabled />
                              </div>
                            </div>
                            <!-- !end  of Sphére -->
                            <!-- !cylindre -->
                            <div class="cylindre blocCyclop" *ngIf="previousConsultation">
                              <h6>Cylindre</h6>
                              <div class="noteDroit">
                                <input type="text" maxlength="10" class="inputCyclop form-control input-refraction" [(ngModel)]="previousConsultation.refraction.acuitevisueldeloin.oielDroit.cylindre" disabled />
                              </div>
                            </div>
                            <!-- !end od cylindre -->
                            <!-- !axe -->
                            <div class="axe blocCyclop" *ngIf="previousConsultation">
                              <h6>Axe</h6>
                              <div class="noteDroit">
                                <input type="text" maxlength="10" class="inputCyclop form-control input-refraction" [(ngModel)]="previousConsultation.refraction.acuitevisueldeloin.oielDroit.axe" disabled />
                              </div>
                            </div>
                            <!-- !end of axe -->
                            <!----------------end ancien consultaion -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- echelle snellen -->
                  <div *ngIf="examRefraction.refraction.acuitevisueldeloin.echelle==='snellen'">
                    <div id="noteAVSC">
                      <div class="noteDroit">
                        <select class="form-control input-refraction" [(ngModel)]="examRefraction.refraction.acuitevisueldeloin.oielDroit.avsc">
                          <option *ngFor="let element of echelleSnellenList" [value]="element">{{element}}</option>
                        </select>
                      </div>
                      <h4 id="colorBlack">AVSC</h4>
                      <div class="noteDroit">
                        <select class="form-control input-refraction" [(ngModel)]="examRefraction.refraction.acuitevisueldeloin.oielGauche.avsc">
                          <option *ngFor="let element of echelleSnellenList" [value]="element">{{element}}</option>
                        </select>
                      </div>
                    </div>
                    <div id="noteAVSC">
                      <div class="noteDroit">
                        <select class="form-control input-refraction" [(ngModel)]="examRefraction.refraction.acuitevisueldeloin.oielDroit.avac">
                          <option *ngFor="let element of echelleSnellenList" [value]="element">{{element}}</option>
                        </select>
                      </div>
                      <h4 id="colorBlack">AVAC</h4>
                      <div class="noteDroit">
                        <select class="form-control input-refraction" [(ngModel)]="examRefraction.refraction.acuitevisueldeloin.oielGauche.avac">
                          <option *ngFor="let element of echelleSnellenList" [value]="element">{{element}}</option>
                        </select>
                      </div>
                    </div>
                    <!-- Réfraction finale -->
                    <div>
                      <h4 id="colorBlack" class="text-center">Réfraction finale</h4>
                      <div [ngClass]="previousConsultation?'col-12 sansCycloBlocRef':'col-12 sansCyclo'">
                        <div class="cadreAcuiteOeilDroit">
                          <div class="oeilTitreSection">
                            <img src="assets/images/oeil.png" alt="icon oeil" class="iconEye" />
                            <h5 id="OeilTitle1">Oeil droit</h5>
                          </div>
                          <div id="refObjectivSection">
                            <!-- !Sphére -->
                            <div class="Sphère blocCyclop">
                              <h6>Sphère</h6>
                              <div class="noteDroit">
                                <input type="text" maxlength="10" class="inputCyclop form-control input-refraction" [(ngModel)]="examRefraction.refraction.acuitevisueldeloin.oielDroit.sphere" (change)="changeLoinODSph($event.target.value)" onkeypress="return /[a-zA-Z0-9 .+-]/i.test(event.key)" />
                                <div class="number-input mr-1">
                                  <div class="arrow arrow-up" (click)="incrementLoinODSph()">▲</div>
                                  <br />
                                  <div class="arrow arrow-down" (click)="decrementLoinODSph()">▼</div>
                                </div>
                              </div>
                            </div>
                            <!-- !end  of Sphére -->
                            <!-- !cylindre -->
                            <div class="cylindre blocCyclop">
                              <h6>Cylindre</h6>
                              <div class="noteDroit">
                                <input type="text" maxlength="10" class="inputCyclop form-control input-refraction" [(ngModel)]="examRefraction.refraction.acuitevisueldeloin.oielDroit.cylindre" (change)="changeLoinLoinODCy($event.target.value)" onkeypress="return /[a-zA-Z0-9 .+-]/i.test(event.key)" />
                                <div class="number-input">
                                  <div class="arrow arrow-up" (click)="incrementLoinODCy()">▲</div>
                                  <br />
                                  <div class="arrow arrow-down" (click)="decrementLoinODCy()">▼</div>
                                </div>
                              </div>
                            </div>
                            <!-- !end od cylindre -->
                            <!-- !axe -->
                            <div class="axe blocCyclop">
                              <h6>Axe</h6>

                              <div class="noteDroit">
                                <input type="text" maxlength="10" class="inputCyclop form-control input-refraction" [(ngModel)]="examRefraction.refraction.acuitevisueldeloin.oielDroit.axe" (change)="changeLoinODAxe($event.target.value)" onkeypress="return /[a-zA-Z0-9 .]/i.test(event.key)" />
                                <div class="number-input">
                                  <div class="arrow arrow-up" (click)="incrementLoinODAxe()">▲</div>
                                  <br />
                                  <div class="arrow arrow-down" (click)="decrementLoinODAxe()">▼</div>
                                </div>
                              </div>
                            </div>
                            <!-- !end of axe -->
                            <!-- bloc RV -->
                            <div class="checkBoxrefObjectiv">
                              <input type="checkbox" class="radiotypeinput" [checked]="examRefraction.refraction.acuitevisueldeloin.oielDroit.rv==='R < V'" (change)="selectRVOeilDroitLoin('R < V')" />
                              <p class="ACcheckboxLabel">R < V </p>
                            </div>
                            <div class="checkBoxrefObjectiv">
                              <input type="checkbox" class="radiotypeinput" [checked]="examRefraction.refraction.acuitevisueldeloin.oielDroit.rv==='R = V'" (change)="selectRVOeilDroitLoin('R = V')" />
                              <p class="ACcheckboxLabel">R = V </p>
                            </div>
                            <div class="checkBoxrefObjectiv">
                              <input type="checkbox" class="radiotypeinput" [checked]="examRefraction.refraction.acuitevisueldeloin.oielDroit.rv==='R > V'" (change)="selectRVOeilDroitLoin('R > V')" />
                              <p class="ACcheckboxLabel">R > V </p>
                            </div>
                            <!-- bloc RV -->
                            <!---------------- ancien consultaion -->
                            <!-- !Sphére -->
                            <div class="Sphère blocCyclop" *ngIf="previousConsultation">
                              <h6>Sphère</h6>
                              <div class="noteDroit">
                                <input type="text" maxlength="10" class="inputCyclop form-control input-refraction" [(ngModel)]="previousConsultation.refraction.acuitevisueldeloin.oielDroit.sphere" disabled />
                              </div>
                            </div>
                            <!-- !end  of Sphére -->
                            <!-- !cylindre -->
                            <div class="cylindre blocCyclop" *ngIf="previousConsultation">
                              <h6>Cylindre</h6>
                              <div class="noteDroit">
                                <input type="text" maxlength="10" class="inputCyclop form-control input-refraction" [(ngModel)]="previousConsultation.refraction.acuitevisueldeloin.oielDroit.cylindre" disabled />
                              </div>
                            </div>
                            <!-- !end od cylindre -->
                            <!-- !axe -->
                            <div class="axe blocCyclop" *ngIf="previousConsultation">
                              <h6>Axe</h6>
                              <div class="noteDroit">
                                <input type="text" maxlength="10" class="inputCyclop form-control input-refraction" [(ngModel)]="previousConsultation.refraction.acuitevisueldeloin.oielDroit.axe" disabled />
                              </div>
                            </div>
                            <!-- !end of axe -->
                            <!----------------end ancien consultaion -->
                          </div>
                        </div>
                        <h5 id="ancienneConsult" *ngIf="previousConsultation">Ancienne consultation</h5>
                        <div class="cadreAcuiteOeilGauche">
                          <div class="oeilTitreSection">
                            <img src="assets/images/oeil.png" alt="icon oeil" class="iconEye" />
                            <h5 id="OeilTitle1">Oeil gauche</h5>
                          </div>
                          <div id="refObjectivSection">
                            <!-- !Sphére -->
                            <div class="Sphère blocCyclop">
                              <h6>Sphère</h6>
                              <div class="noteDroit">
                                <input type="text" maxlength="10" class="inputCyclop form-control input-refraction" [(ngModel)]="examRefraction.refraction.acuitevisueldeloin.oielGauche.sphere" (change)="changeLoinLoinOGSph($event.target.value)" onkeypress="return /[a-zA-Z0-9 .+-]/i.test(event.key)" />
                                <div class="number-input">
                                  <div class="arrow arrow-up" (click)="incrementLoinOGSph()">▲</div>
                                  <br />
                                  <div class="arrow arrow-down" (click)="decrementLoinOGSph()">▼</div>
                                </div>
                              </div>
                            </div>
                            <!-- !end  of Sphére -->
                            <!-- !cylindre -->
                            <div class="cylindre blocCyclop">
                              <h6>Cylindre</h6>
                              <div class="noteDroit">
                                <input type="text" maxlength="10" class="inputCyclop form-control input-refraction" [(ngModel)]="examRefraction.refraction.acuitevisueldeloin.oielGauche.cylindre" (change)="changeLoinOGCy($event.target.value)" onkeypress="return /[a-zA-Z0-9 .+-]/i.test(event.key)" />
                                <div class="number-input">
                                  <div class="arrow arrow-up" (click)="incrementLoinOGCy()">▲</div>
                                  <br />
                                  <div class="arrow arrow-down" (click)="decrementLoinOGCy()">▼</div>
                                </div>
                              </div>
                            </div>
                            <!-- !end of cylindre -->
                            <!-- !axe -->
                            <div class="axe blocCyclop">
                              <h6>Axe</h6>
                              <div class="noteDroit">
                                <input type="text" maxlength="10" class="inputCyclop form-control input-refraction" [(ngModel)]="examRefraction.refraction.acuitevisueldeloin.oielGauche.axe" (change)="changeLoinOGAxe($event.target.value)" onkeypress="return /[a-zA-Z0-9 .]/i.test(event.key)" />
                                <div class="number-input">
                                  <div class="arrow arrow-up" (click)="incrementLoinOGAxe()">▲</div>
                                  <br />
                                  <div class="arrow arrow-down" (click)="decrementLoinOGAxe()">▼</div>
                                </div>
                              </div>
                            </div>
                            <!-- !end of axe -->
                            <!-- bloc RV -->
                            <div class="checkBoxrefObjectiv">
                              <input type="checkbox" class="radiotypeinput" [checked]="examRefraction.refraction.acuitevisueldeloin.oielGauche.rv==='R < V'" (change)="selectRVOeilGaucheLoin('R < V')" />
                              <p class="ACcheckboxLabel">R < V </p>
                            </div>
                            <div class="checkBoxrefObjectiv">
                              <input type="checkbox" class="radiotypeinput" [checked]="examRefraction.refraction.acuitevisueldeloin.oielGauche.rv==='R = V'" (change)="selectRVOeilGaucheLoin('R = V')" />
                              <p class="ACcheckboxLabel">R = V </p>
                            </div>
                            <div class="checkBoxrefObjectiv">
                              <input type="checkbox" class="radiotypeinput" [checked]="examRefraction.refraction.acuitevisueldeloin.oielGauche.rv==='R > V'" (change)="selectRVOeilGaucheLoin('R > V')" />
                              <p class="ACcheckboxLabel">R > V </p>
                            </div>
                            <!-- bloc RV -->
                            <!---------------- ancien consultaion -->
                            <!-- !Sphére -->
                            <div class="Sphère blocCyclop" *ngIf="previousConsultation">
                              <h6>Sphère</h6>
                              <div class="noteDroit">
                                <input type="text" maxlength="10" class="inputCyclop form-control input-refraction" [(ngModel)]="previousConsultation.refraction.acuitevisueldeloin.oielGauche.sphere" disabled />
                              </div>
                            </div>
                            <!-- !end  of Sphére -->
                            <!-- !cylindre -->
                            <div class="cylindre blocCyclop" *ngIf="previousConsultation">
                              <h6>Cylindre</h6>
                              <div class="noteDroit">
                                <input type="text" maxlength="10" class="inputCyclop form-control input-refraction" [(ngModel)]="previousConsultation.refraction.acuitevisueldeloin.oielGauche.cylindre" disabled />
                              </div>
                            </div>
                            <!-- !end od cylindre -->
                            <!-- !axe -->
                            <div class="axe blocCyclop" *ngIf="previousConsultation">
                              <h6>Axe</h6>
                              <div class="noteDroit">
                                <input type="text" maxlength="10" class="inputCyclop form-control input-refraction" [(ngModel)]="previousConsultation.refraction.acuitevisueldeloin.oielGauche.axe" disabled />
                              </div>
                            </div>
                            <!-- !end of axe -->
                            <!----------------end ancien consultaion -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                </div>
                <div class="row m-t-20">
                  <div>
                    <label>Oeil directeur de loin</label>
                    <br />
                    <div class="checkBoxbloc">
                      <input type="checkbox" class="radiotypeinput" [checked]="examRefraction.refraction.acuitevisueldeloin.oeilDirecteur==='oielDroit'" (change)="selectOeilDroitAVLoin()" />
                      <p class="ACcheckboxLabel">Oeil Droit</p>
                    </div>
                    <div class="checkBoxbloc1">
                      <input type="checkbox" class="radiotypeinput" [checked]="examRefraction.refraction.acuitevisueldeloin.oeilDirecteur==='oielGauche'" (change)="selectOeilGaucheAVLoin()" />
                      <p class="ACcheckboxLabel">Oeil Gauche</p>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <label class="switch">
                <input type="checkbox" [(ngModel)]="examRefraction.refraction.acuitevisueldepres.present" />
                <span class="slider round"></span>
              </label>

              <p id="textswitchtoggle">Acuité visuel de près</p>
              <div *ngIf="examRefraction.refraction.acuitevisueldepres.present">
                <div id="acuiteVisLoin">
                  <br />
                  <div id="headerAcuiteLoin">
                    <h6>Échelle :</h6>
                    <div>
                      <input type="checkbox" class="checkEchelleAcuit" [checked]="examRefraction.refraction.acuitevisueldepres.echelle==='parinaud'" />
                      <label>Parinaud</label>
                    </div>
                  </div>
                  <div *ngIf="echelleParinaud">
                    <div id="noteAVSC">
                      <div class="noteDroit">
                        <select class="form-control input-refraction" [(ngModel)]="examRefraction.refraction.acuitevisueldepres.oielDroit.avsc">
                          <option *ngFor="let val of echelleParinaudList" [value]="val">{{val}}</option>
                        </select>
                      </div>
                      <h4 id="colorBlack">AVSC</h4>
                      <div class="noteDroit">
                        <select class="form-control input-refraction" [(ngModel)]="examRefraction.refraction.acuitevisueldepres.oielGauche.avsc">
                          <option *ngFor="let val of echelleParinaudList" [value]="val">{{val}}</option>
                        </select>
                      </div>

                    </div>
                    <div id="noteAVSC">
                      <div class="noteDroit">
                        <select class="form-control input-refraction" [(ngModel)]="examRefraction.refraction.acuitevisueldepres.oielDroit.avac">
                          <option *ngFor="let val of echelleParinaudList" [value]="val">{{val}}</option>
                        </select>
                      </div>
                      <h4 id="colorBlack">AVAC</h4>
                      <div class="noteDroit">
                        <select class="form-control input-refraction" [(ngModel)]="examRefraction.refraction.acuitevisueldepres.oielGauche.avac">
                          <option *ngFor="let val of echelleParinaudList" [value]="val">{{val}}</option>
                        </select>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6 acuitePres">
                        <div id="addbloc1">
                          <label id="labelAcuitePre">Add</label>
                          <div class="noteDroit">
                            <input type="text" maxlength="5" id="numberInput9" class="numberInput form-control input-refraction" [(ngModel)]="examRefraction.refraction.acuitevisueldepres.oielDroit.add" (input)="onchangeAdddroit($event.target.value)" onkeypress="return /[0-9+.]/i.test(event.key)" />
                            <div class="number-input">
                              <div class="arrow arrow-up" (click)="increment9()">▲</div>
                              <br />
                              <div class="arrow arrow-down" (click)="decrement9()">▼</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-6 acuitePres">
                        <div id="addbloc2">
                          <label id="labelAcuitePre">Add</label>
                          <div class="noteDroit">
                            <input type="text" maxlength="5" class="numberInput form-control input-refraction" value="0" [(ngModel)]="examRefraction.refraction.acuitevisueldepres.oielGauche.add" (input)="onchangeAddGauche($event.target.value)" onkeypress="return /[0-9+.]/i.test(event.key)" />
                            <div class="number-input">
                              <div class="arrow arrow-up" (click)="increment10()">▲</div>
                              <br />
                              <div class="arrow arrow-down" (click)="decrement10()">▼</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Réfraction finale -->
                    <div>
                      <h4 id="colorBlack" class="text-center">Réfraction finale</h4>
                      <div [ngClass]="previousConsultation?'col-12 sansCycloBlocRef':'col-12 sansCyclo'">
                        <div class="cadreAcuiteOeilDroit">
                          <div class="oeilTitreSection">
                            <img src="assets/images/oeil.png" alt="icon oeil" class="iconEye" />
                            <h5 id="OeilTitle1">Oeil droit</h5>
                          </div>
                          <div id="refObjectivSection">
                            <!-- !Sphére -->
                            <div class="Sphère blocCyclop">
                              <h6>Sphère</h6>
                              <div class="noteDroit">
                                <input type="text" maxlength="10" class="inputCyclop form-control input-refraction" [(ngModel)]="examRefraction.refraction.acuitevisueldepres.oielDroit.sphere" (change)="changePresODSph($event.target.value)" onkeypress="return /[a-zA-Z0-9 .+-]/i.test(event.key)" />
                                <div class="number-input mr-1">
                                  <div class="arrow arrow-up" (click)="incrementPresODSph()">▲</div>
                                  <br />
                                  <div class="arrow arrow-down" (click)="decrementPresODSph()">▼</div>
                                </div>
                              </div>
                            </div>
                            <!-- !end  of Sphére -->
                            <!-- !cylindre -->
                            <div class="cylindre blocCyclop">
                              <h6>Cylindre</h6>
                              <div class="noteDroit">
                                <input type="text" maxlength="10" class="inputCyclop form-control input-refraction" [(ngModel)]="examRefraction.refraction.acuitevisueldepres.oielDroit.cylindre" (change)="changePresPresODCy($event.target.value)" onkeypress="return /[a-zA-Z0-9 .+-]/i.test(event.key)" />
                                <div class="number-input">
                                  <div class="arrow arrow-up" (click)="incrementPresODCy()">▲</div>
                                  <br />
                                  <div class="arrow arrow-down" (click)="decrementPresODCy()">▼</div>
                                </div>
                              </div>
                            </div>
                            <!-- !end od cylindre -->
                            <!-- !axe -->
                            <div class="axe blocCyclop">
                              <h6>Axe</h6>

                              <div class="noteDroit">
                                <input type="text" maxlength="10" class="inputCyclop form-control input-refraction" [(ngModel)]="examRefraction.refraction.acuitevisueldepres.oielDroit.axe" (change)="changePresODAxe($event.target.value)" onkeypress="return /[a-zA-Z0-9 .]/i.test(event.key)" />
                                <div class="number-input">
                                  <div class="arrow arrow-up" (click)="incrementPresODAxe()">▲</div>
                                  <br />
                                  <div class="arrow arrow-down" (click)="decrementPresODAxe()">▼</div>
                                </div>
                              </div>
                            </div>
                            <!-- !end of axe -->
                            <!-- bloc RV -->
                            <div class="checkBoxrefObjectiv">
                              <input type="checkbox" class="radiotypeinput" [checked]="examRefraction.refraction.acuitevisueldepres.oielDroit.rv==='R < V'" (change)="selectRVOeilDroitPres('R < V')" />
                              <p class="ACcheckboxLabel">R < V </p>
                            </div>
                            <div class="checkBoxrefObjectiv">
                              <input type="checkbox" class="radiotypeinput" [checked]="examRefraction.refraction.acuitevisueldepres.oielDroit.rv==='R = V'" (change)="selectRVOeilDroitPres('R = V')" />
                              <p class="ACcheckboxLabel">R = V </p>
                            </div>
                            <div class="checkBoxrefObjectiv">
                              <input type="checkbox" class="radiotypeinput" [checked]="examRefraction.refraction.acuitevisueldepres.oielDroit.rv==='R > V'" (change)="selectRVOeilDroitPres('R > V')" />
                              <p class="ACcheckboxLabel">R > V </p>
                            </div>
                            <!-- bloc RV -->
                            <!---------------- ancien consultaion -->
                            <!-- !Sphére -->
                            <div class="Sphère blocCyclop" *ngIf="previousConsultation">
                              <h6>Sphère</h6>
                              <div class="noteDroit">
                                <input type="text" maxlength="10" class="inputCyclop form-control input-refraction" [(ngModel)]="previousConsultation.refraction.acuitevisueldepres.oielDroit.sphere" disabled />
                              </div>
                            </div>
                            <!-- !end  of Sphére -->
                            <!-- !cylindre -->
                            <div class="cylindre blocCyclop" *ngIf="previousConsultation">
                              <h6>Cylindre</h6>
                              <div class="noteDroit">
                                <input type="text" maxlength="10" class="inputCyclop form-control input-refraction" [(ngModel)]="previousConsultation.refraction.acuitevisueldepres.oielDroit.cylindre" disabled />
                              </div>
                            </div>
                            <!-- !end od cylindre -->
                            <!-- !axe -->
                            <div class="axe blocCyclop" *ngIf="previousConsultation">
                              <h6>Axe</h6>
                              <div class="noteDroit">
                                <input type="text" maxlength="10" class="inputCyclop form-control input-refraction" [(ngModel)]="previousConsultation.refraction.acuitevisueldepres.oielDroit.axe" disabled />
                              </div>
                            </div>
                            <!-- !end of axe -->
                            <!----------------end ancien consultaion -->
                          </div>
                        </div>
                        <h5 id="ancienneConsult" *ngIf="previousConsultation">Ancienne consultation</h5>
                        <div class="cadreAcuiteOeilGauche">
                          <div class="oeilTitreSection">
                            <img src="assets/images/oeil.png" alt="icon oeil" class="iconEye" />
                            <h5 id="OeilTitle1">Oeil gauche</h5>
                          </div>
                          <div id="refObjectivSection">
                            <!-- !Sphére -->
                            <div class="Sphère blocCyclop">
                              <h6>Sphère</h6>
                              <div class="noteDroit">
                                <input type="text" maxlength="10" class="inputCyclop form-control input-refraction" [(ngModel)]="examRefraction.refraction.acuitevisueldepres.oielGauche.sphere" (change)="changePresPresOGSph($event.target.value)" onkeypress="return /[a-zA-Z0-9 .+-]/i.test(event.key)" />
                                <div class="number-input">
                                  <div class="arrow arrow-up" (click)="incrementPresOGSph()">▲</div>
                                  <br />
                                  <div class="arrow arrow-down" (click)="decrementPresOGSph()">▼</div>
                                </div>
                              </div>
                            </div>
                            <!-- !end  of Sphére -->
                            <!-- !cylindre -->
                            <div class="cylindre blocCyclop">
                              <h6>Cylindre</h6>
                              <div class="noteDroit">
                                <input type="text" maxlength="10" class="inputCyclop form-control input-refraction" [(ngModel)]="examRefraction.refraction.acuitevisueldepres.oielGauche.cylindre" (change)="changePresOGCy($event.target.value)" onkeypress="return /[a-zA-Z0-9 .+-]/i.test(event.key)" />
                                <div class="number-input">
                                  <div class="arrow arrow-up" (click)="incrementPresOGCy()">▲</div>
                                  <br />
                                  <div class="arrow arrow-down" (click)="decrementPresOGCy()">▼</div>
                                </div>
                              </div>
                            </div>
                            <!-- !end of cylindre -->
                            <!-- !axe -->
                            <div class="axe blocCyclop">
                              <h6>Axe</h6>
                              <div class="noteDroit">
                                <input type="text" maxlength="10" class="inputCyclop form-control input-refraction" [(ngModel)]="examRefraction.refraction.acuitevisueldepres.oielGauche.axe" (change)="changePresOGAxe($event.target.value)" onkeypress="return /[a-zA-Z0-9 .]/i.test(event.key)" />
                                <div class="number-input">
                                  <div class="arrow arrow-up" (click)="incrementPresOGAxe()">▲</div>
                                  <br />
                                  <div class="arrow arrow-down" (click)="decrementPresOGAxe()">▼</div>
                                </div>
                              </div>
                            </div>
                            <!-- !end of axe -->
                            <!-- bloc RV -->
                            <div class="checkBoxrefObjectiv">
                              <input type="checkbox" class="radiotypeinput" [checked]="examRefraction.refraction.acuitevisueldepres.oielGauche.rv==='R < V'" (change)="selectRVOeilGauchePres('R < V')" />
                              <p class="ACcheckboxLabel">R < V </p>
                            </div>
                            <div class="checkBoxrefObjectiv">
                              <input type="checkbox" class="radiotypeinput" [checked]="examRefraction.refraction.acuitevisueldepres.oielGauche.rv==='R = V'" (change)="selectRVOeilGauchePres('R = V')" />
                              <p class="ACcheckboxLabel">R = V </p>
                            </div>
                            <div class="checkBoxrefObjectiv">
                              <input type="checkbox" class="radiotypeinput" [checked]="examRefraction.refraction.acuitevisueldepres.oielGauche.rv==='R > V'" (change)="selectRVOeilGauchePres('R > V')" />
                              <p class="ACcheckboxLabel">R > V </p>
                            </div>
                            <!-- bloc RV -->
                            <!---------------- ancien consultaion -->
                            <!-- !Sphére -->
                            <div class="Sphère blocCyclop" *ngIf="previousConsultation">
                              <h6>Sphère</h6>
                              <div class="noteDroit">
                                <input type="text" maxlength="10" class="inputCyclop form-control input-refraction" [(ngModel)]="previousConsultation.refraction.acuitevisueldepres.oielGauche.sphere" disabled />
                              </div>
                            </div>
                            <!-- !end  of Sphére -->
                            <!-- !cylindre -->
                            <div class="cylindre blocCyclop" *ngIf="previousConsultation">
                              <h6>Cylindre</h6>
                              <div class="noteDroit">
                                <input type="text" maxlength="10" class="inputCyclop form-control input-refraction" [(ngModel)]="previousConsultation.refraction.acuitevisueldepres.oielGauche.cylindre" disabled />
                              </div>
                            </div>
                            <!-- !end od cylindre -->
                            <!-- !axe -->
                            <div class="axe blocCyclop" *ngIf="previousConsultation">
                              <h6>Axe</h6>
                              <div class="noteDroit">
                                <input type="text" maxlength="10" class="inputCyclop form-control input-refraction" [(ngModel)]="previousConsultation.refraction.acuitevisueldepres.oielGauche.axe" disabled />
                              </div>
                            </div>
                            <!-- !end of axe -->
                            <!----------------end ancien consultaion -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <div class="row m-t-20">
                  <div>
                    <label>Oeil directeur de près</label>
                    <br />
                    <div class="checkBoxbloc">
                      <input type="checkbox" class="radiotypeinput" [checked]="examRefraction.refraction.acuitevisueldepres.oeilDirecteur==='oielDroit'" (change)="selectOeilDroitAVPres()" />
                      <p class="ACcheckboxLabel">Oeil Droit</p>
                    </div>
                    <div class="checkBoxbloc1">
                      <input type="checkbox" class="radiotypeinput" [checked]="examRefraction.refraction.acuitevisueldepres.oeilDirecteur==='oielGauche'" (change)="selectOeilGaucheAVPres()" />
                      <p class="ACcheckboxLabel">Oeil Gauche</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>


</div>
<!-----------    End  Refraction       ----->